<template>
  <v-container>
    <v-row justify="center">
      <v-col sm="4">
        <v-card>
          <v-toolbar dark flat dense color="indigo lighten-2">
            <v-spacer>
              <v-toolbar-title>
                <span class="headline text-md-center">Welcome</span>
              </v-toolbar-title>
            </v-spacer>
          </v-toolbar>

          <v-card-text>
            <v-img :src="logo"></v-img>
            <p class="mt-4 text-sm-center grey--text title font-weight-light">
              You can use your Arup account to automatically login
            </p>

            <awf-sso-form></awf-sso-form>

            <!-- <v-tab-item :value="'tab-2'">
                  <awf-signin-form>

                  </awf-signin-form>
                </v-tab-item>

                <v-tab-item :value="'tab-3'">
                  <awf-signup-form>

            </awf-signup-form>-->
            <!-- </v-tab-item> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AwfSsoForm from "../components/signin/AwfSsoForm.vue";
// import AwfSigninForm from "../components/AwfSigninForm.vue";
// import AwfSignupForm from "../components/AwfSignupForm.vue";
export default {
  components: { AwfSsoForm },
  data() {
    return {
      valid: false,
      tabs: "tab-1",
      show: false,
      logo: require("../assets/images/logos/AWF_Logo_Colored.png")
    };
  }
};
</script>
