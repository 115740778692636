<template>
  <v-container>
    <nav-header
      :title="header.title"
      :subtitle="header.subtitle"
      :btns="header.btns"
    ></nav-header>

    <v-row class="mt-4">
      <v-col sm="12" v-for="(card, i) in cards" :key="card.title" class="mb-4">
        <v-row align="center">
          <v-col sm="12" md="5" v-if="card.image && i % 2 != 0">
            <v-img height="300px" :src="card.image" contain></v-img>
          </v-col>
          <v-col md="7">
            <v-card class="transparent" flat>
              <v-card-title v-if="card.title">
                <p :class="card.titleSize">{{ card.title }}</p>
              </v-card-title>
              <v-card-text class="py-0">
                <p class="title font-weight-light text-sm-left grey--text">
                  <vue-markdown :source="card.text"></vue-markdown>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="12" md="5" v-if="card.image && i % 2 == 0">
            <v-img height="300px" :src="card.image" contain></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
import VueMarkdown from "vue-markdown";

export default {
  components: { NavHeader, VueMarkdown },
  data() {
    return {
      header: {
        title: "Welcome to AWF",
        subtitle:
          "A compute and data management service that allows you to create and run automated workflows.",
        btns: [
          {
            icon: "mdi-plus",
            tooltip: "Run a workflow",
            nav: "/run"
          }
          // {
          //   icon: "mdi-email",
          //   tooltip: "Contact us",
          //   nav: "/contact",
          //   flat: true
          // }
        ]
      },
      cards: [
        {
          title: "What does it do?",
          titleSize: "headline",
          text:
            "<p>AWF helps you orchestrate workflows. You can upload your automation scripts and define the sequence of steps you want to run them in.</p>" +
            "<p>AWF runs the workflows for you and stores the workflow output in a database so that you can retrieve it anytime. You can run the same workflow any number of times.</p>" +
            "<p>You can make generic workflows that can be shared with others, or workflows that are tailored specifically for a project. </p> " +
            "",
          image: require("../assets/compute_icon.png")
        },
        {
          title: "What is a workflow?",
          titleSize: "headline",
          text:
            "<p>A workflow is a series of activities that need to be executed in order to achieve a desired outcome. Each activity can be a manual or automated step, and takes a set of inputs and produces a set of outputs. Automating as many of these activities reduces the time needed to execute a workflow, and ensures consistency in what is delivered. </p>" +
            "<p></p>" +
            "<p>The diagram on the right is a simplified illustration of what an engineering design workflow might look like. Each step in the workflow processes a set of inputs and produces a set of outputs. Each step usually needs to be run with a piece of engineering software. It may even need to communicate with external services such as Speckle. </p>" +
            "<p>Once you've defined a workflow you're able to run it any number of times. Simply upload your workflow inputs, run the workflow, and then download the results.</p>" +
            "<p>We've created several workflows that you can start using already. </p>",
          image: require("../assets/awf_workflow.png")
        }
        // {
        //   title: "Why would I use this?",
        //   titleSize: "headline"
        // },
        // {
        //   title: "How does it work?",
        //   titleSize: "headline"
        // },
        // {
        //   title: "How do I get started?",
        //   titleSize: "headline"
        // },
        // {
        //   title: "How do I create my own workflow?",
        //   titleSize: "headline"
        // }
      ]
    };
  }
};
</script>
