//Need this for webpack to copy index.html file into dist folder after npm run build

// Todo: Figure out how to get script src=./dist/build.js in index.html to chance to src=build.js when running npm run build
require("file-loader?name=[name].[ext]!../public/index.html");

import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import UploadButton from "vuetify-upload-button";
import VueMarkdown from "vue-markdown";
import VueFileAgent from "vue-file-agent";
import VueApexCharts from "vue-apexcharts";

import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import "vuetify/dist/vuetify.min.css"; // Ensure you are using css-loader
import { router } from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";

Vue.use(VueFileAgent);
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(UploadButton);
Vue.use(VueMarkdown);
Vue.component("apexchart", VueApexCharts);

export const eventBus = new Vue();

new Vue({
  render: h => h(App),
  store,
  router,
  vuetify
}).$mount("#app");
