<template>
  <v-card flat color="indigo lighten-5">
    <v-card-text>
      <v-row>
        <v-col sm="1"
          ><span class="indigo--text">{{ request }}</span></v-col
        >
        <v-col sm="11"
          ><span class="indigo--text">{{ url }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    request: { type: String },
    url: { type: String }
  },
  computed: {}
};
</script>
