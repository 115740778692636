<template>
  <v-card flat>
    <v-card-text class="pb-0">
      <v-row align="start" justify="center">
        <v-col cols="12" lg="6" class="py-0">
          <v-text-field
            @change="argChanged(param)"
            v-model="param.value"
            :rules="requiredRules"
            :ref="param.name"
            :label="param.label"
            :placeholder="param.default"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="11" lg="5" xl="3" class="py-0">
          <job-number-search
            ref="input-field"
            @jobNumberSelected="selectedJobNumber"
          ></job-number-search>
        </v-col>
        <v-col cols="1">
          <v-btn
            large
            icon
            @mouseenter="dialog = true"
            @mouseleave="dialog = false"
          >
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" width="600">
            <awf-instructions></awf-instructions>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import JobNumberSearch from "@/components/run/JobNumberSearch";
import AwfInstructions from "@/components//run/AwfInstructions";

export default {
  components: {
    JobNumberSearch,
    AwfInstructions
  },
  props: {
    parameters: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      requiredRules: [v => !!v || "Field is required"],
      jobNumber: null,
      dialog: false
    };
  },
  computed: {
    param() {
      return this.parameters.find(p => p.name === "object_id");
    }
  },
  methods: {
    argChanged(param) {
      this.$emit("argChanged", param);
    },
    selectedJobNumber(event) {
      this.jobNumber = event;
      this.$emit("jobNumberSelected", event);
    }
  }
};
</script>
