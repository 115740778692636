<template>
  <v-container fluid style="width:100%; height:100%" class="py-0">
    <v-row justify="end">
      <v-col class="text-right py-0" cols="12">
        <v-btn icon @click="fit">
          <v-icon>mdi-fit-to-page-outline</v-icon>
        </v-btn>
        <v-btn icon @click="zoomIn">
          <v-icon>mdi-magnify-plus-outline</v-icon>
        </v-btn>
        <v-btn icon @click="zoomOut">
          <v-icon>mdi-magnify-minus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div id="cy" ref="cy"></div>
  </v-container>
</template>

<script>
import cytoscape from "cytoscape";
import { eventBus } from "../../main";
var nodeHtmlLabel = require("cytoscape-node-html-label");
nodeHtmlLabel(cytoscape);

export default {
  props: {
    inputs: { type: Array },
    submitted: { type: Boolean },
    workflowConfig: { type: Object }
  },
  data() {
    return {
      elements: [],
      invalidNodes: [],
      cyConfig: {
        autolock: false,
        headless: false,
        zoomingEnabled: true,
        zoom: 2,
        minZoom: 1,
        maxZoom: 10,
        wheelSensitivity: 0.1,
        panningEnabled: true,
        boxSelectionEnabled: false,
        autounselectify: true,
        style: [
          // the stylesheet for the graph

          {
            selector: "node",
            style: {
              "background-color": "#FFFFFF",
              "border-color": "#E57373",
              "border-width": "2px",
              "font-size": "10px",
              "text-valign": "center",
              "text-wrap": "wrap",
              "text-max-width": "110px",
              label: "data(id)",
              shape: "round-rectangle",
              width: "70px",
              height: "30px"
            }
          },
          {
            selector: ".profileTask",
            style: {
              "background-fit": "contain",
              "background-color": "#FFFFFF",
              "border-color": "#E57373",
              "border-width": "0px",
              "text-halign": "right",
              "text-valign": "center",
              "text-wrap": "wrap",
              "text-max-width": "70px",
              "text-margin-x": "8px",

              label: "data(id)",
              shape: "round-rectangle",
              width: "40px",
              height: "40px"
            }
          },
          {
            selector: ".primer",
            style: {
              "background-image": require("../../assets/images/logos/primer.png")
            }
          },
          {
            selector: ".ls-dyna",
            style: {
              "background-image": require("../../assets/images/logos/ls-dyna.png")
            }
          },
          {
            selector: ".t-his",
            style: {
              "background-image": require("../../assets/images/logos/t-his.png")
            }
          },
          {
            selector: ".d3plot",
            style: {
              "background-image": require("../../assets/images/logos/d3plot.png")
            }
          },
          {
            selector: ".fds",
            style: {
              "background-image": require("../../assets/images/logos/fds.png")
            }
          },
          {
            selector: ".reporter",
            style: {
              "background-image": require("../../assets/images/logos/reporter.png")
            }
          },
          {
            selector: ".gsa",
            style: {
              "background-image": require("../../assets/images/logos/gsa.png")
            }
          },
          {
            selector: ".speckle",
            style: {
              "background-image": require("../../assets/images/logos/speckle.png")
            }
          },
          {
            selector: ".python",
            style: {
              "background-image": require("../../assets/images/logos/python.png")
            }
          },
          {
            selector: ".arup_compute",
            style: {
              "background-image": require("../../assets/images/logos/arupcompute.png")
            }
          },
          {
            selector: ".athena",
            style: {
              "background-image": require("../../assets/images/logos/athena.png")
            }
          },
          {
            selector: ".radiance",
            style: {
              "background-image": require("../../assets/images/logos/radiance.jpg")
            }
          },
          {
            selector: ".input",
            style: {
              "background-color": "#FFFFFF",
              "border-color": "#FFC107",
              "border-width": "2px",
              "text-valign": "top",

              label: "data(id)",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: ".input-invalid",
            style: {
              "background-color": "#FFFFFF",
              "border-color": "#FF5252",
              "border-width": "2px",
              "text-valign": "top",

              label: "data(id)",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: ".inputFilled",
            style: {
              "background-color": "#FFC107",
              "border-color": "#FFC107",
              "border-width": "2px",
              "text-valign": "top",

              label: "data(id)",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: ".inputFilled-invalid",
            style: {
              "background-color": "#FF5252",
              "border-color": "#FF5252",
              "border-width": "2px",
              "text-valign": "top",

              label: "data(id)",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: ".success",
            style: {
              "background-color": "#4CAF50",
              "border-color": "#4CAF50",
              "border-width": "2px",
              "text-valign": "center",
              "text-halign": "right",
              "text-margin-x": "8px",
              label: "data(id)",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: ".submit",
            style: {
              "background-color": "#FFFFFF",
              "border-color": "#3F51B5",
              "border-width": "2px",
              "text-valign": "center",
              "text-halign": "right",
              "text-margin-x": "8px",
              label: "Submit",
              shape: "ellipse",
              width: "20px",
              height: "20px"
            }
          },
          {
            selector: "edge",
            style: {
              width: 2,
              "curve-style": "unbundled-bezier",

              "control-point-step-size": 10,

              "line-color": "#ccc",
              "target-arrow-color": "#ccc",
              "target-arrow-shape": "triangle"
              //   "curve-style": "bezier"
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.graph = cytoscape({
      container: document.getElementById("cy"),
      ...this.cyConfig
    });

    this.initGraph();

    //Add click listener on nodes
    let getNode = this.getNode;
    this.graph.on("click", "node", event => {
      let node = getNode(event.target.id());

      eventBus.$emit("nodeClicked", node);
    });

    //Listen for updates in workflow properties
    eventBus.$on("invalidForm", this.invalidateNodes);

    //Refresh graph whenever window is updated
    window.onresize = this.initGraph;
  },
  computed: {
    inputDict() {
      let inputDict = {};
      this.inputs.forEach(input => {
        inputDict[input.name] = input;
      });

      return inputDict;
    },
    width() {
      if (!this.graph) return;
      return this.graph.width();
    },
    height() {
      if (!this.graph) return;
      return this.graph.height();
    },
    currentZoom() {
      return this.graph.zoom();
    }
  },
  watch: {
    inputs() {
      this.initGraph();
    },
    workflowConfig() {
      this.initGraph();
    }
  },
  methods: {
    initGraph() {
      this.elements = [];
      this.graph.elements().remove();

      //Reset cursor
      this.reset();

      let elements = this.addElements(this.inputs, this.workflowConfig);
      this.graph.add(elements);

      this.fit();
      // this.graph.resize();
      // this.graph.center();
      // this.graph.fit();
    },
    invalidateNodes(invalidNodes) {
      this.invalidNodes = invalidNodes;
      this.initGraph();
    },
    initialPosition() {
      return {
        x: document.getElementById("cy").offsetWidth / 2,
        y: 0
      };
    },
    getNode(id) {
      let node = this.elements.find(el => el.data.id == id);
      return node;
    },
    addInputNodes(inputs) {
      // if (!inputs || inputs.length == 0) return;
      let flag = -1;
      let scale = 1;

      inputs.forEach((input, i) => {
        //TO DO: GET RID OF object_id parameter or make it a main field in the workflow run, rather than an instance argument

        //Do not add any parameters with object id in workflow diagram
        if (input.name == "object_id") return;

        //Set the value of the node to be the input object
        let value = { ...input };

        let settings = {
          weight: 20,
          position: { ...this.currentPosition }
        };

        //Add node to graph
        if (input.value) {
          this.addNode(input.label, "inputFilled", value, settings);
        } else if (this.invalidNodes.includes(input.label)) {
          this.addNode(input.label, "input-invalid", value, settings);
        } else {
          this.addNode(input.label, "input", value, settings);
        }

        this.increment(flag * scale * 100, 0);
        flag = flag * -1;
        scale += 1;
      });

      return true;
    },
    iterNodes(modules, callBackFn, name) {
      if (!name || name == "") return;

      //Add Start Task Node
      let m = modules["States"][name];
      if (!m) return;

      //Get next module
      let nextName = m["Next"];

      //Run callback function
      callBackFn(name, m, nextName);

      return this.iterNodes(modules, callBackFn, nextName);
    },
    addTaskNode(name, m) {
      let node;
      let settings = {
        position: { ...this.currentPosition },
        weight: 20
      };
      let value = { ...m };

      if (m["Type"] == "Succeed") {
        if (this.submitted == false) {
          node = this.addNode(name, "submit", value, settings);
        } else {
          node = this.addNode(name, "success", value, settings);
        }
      } else if (m["Profile"] && m["Profile"] != "") {
        node = this.addNode(
          name,
          ["profileTask", m["Profile"]],
          value,
          settings
        );
      } else {
        node = this.addNode(name, "task", value, settings);
      }

      //Increment node position
      this.increment(0, 100);

      return node;
    },
    addTaskEdge(name, m, nextName) {
      let paramLabel;
      //Attach edges for all input parameters for the task
      for (let paramName in m["Parameters"]) {
        paramName = paramName.split(".")[0];
        if (paramName in this.inputDict) {
          paramLabel = this.inputDict[paramName].label;
          this.addEdge(paramLabel, name);
        }
      }

      if (!nextName) return;
      return this.addEdge(name, nextName);
    },
    addElements(inputs, modules) {
      this.reset();

      //Setup input nodes
      this.addInputNodes(inputs);

      //Reset cursor
      this.reset();
      this.increment(0, 100);

      //Add Task Nodes
      if (!modules || !("StartAt" in modules)) return [];
      let name = modules["StartAt"];
      this.iterNodes(modules, this.addTaskNode, name);

      //Connect nodes with edges
      this.iterNodes(modules, this.addTaskEdge, name);

      return this.elements;
    },
    reset() {
      this.currentPosition = this.initialPosition();
    },
    increment(x, y) {
      this.currentPosition.x += x;
      this.currentPosition.y += y;
    },
    formatName(name) {
      name = name.split("_").join(" ");
      //Capitalize every word
      return name
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    },

    addNode(id, cls, value, settings) {
      if (!Array.isArray(cls)) cls = [cls];
      let node = {
        group: "nodes",
        data: {
          id: id,
          value: value
        },
        classes: cls
      };

      // this.graph.nodeHtmlLabel([
      //   {
      //     query: ".inputFilled",
      //     valign: "bottom",
      //     halign: "center",
      //     valignBox: "bottom",
      //     tpl(data) {
      //       if (data.value) {
      //         return '<p class="line1">' + data.value + "</p>";
      //       } else {
      //         return '<p class="line1">' + "</p>";
      //       }
      //     }
      //   }
      // ]);
      this.addNodeLabel();

      node = { ...node, ...settings };

      //Add node to graph
      this.elements.push(node);

      return node;
    },

    addNodeLabel() {
      this.graph.nodeHtmlLabel([
        {
          query: "node",
          valign: "bottom",
          halign: "center",
          valignBox: "bottom",
          tpl(data) {
            if (data.value && data.value.value) {
              if (data.value.value.length > 20) {
                return (
                  '<span class="line1">' +
                  data.value.value.substring(0, 17) +
                  "..." +
                  "</span>"
                );
              } else {
                return '<span class="line1">' + data.value.value + "</span>";
              }
            } else {
              return '<span class="line1">' + "</span>";
            }
          }
        }
      ]);
    },
    addEdge(id1, id2) {
      let edge = {
        group: "edges",
        data: {
          id: id1 + " - " + id2,
          source: id1,
          target: id2
        }
      };

      //Add node to graph
      this.elements.push(edge);
      return edge;
    },
    fit() {
      this.graph.reset();
      this.graph.fit(null, 50);

      //Limit the maximum zoom so that nodes don't show up really large
      if (this.graph.zoom() > 2) {
        this.graph.zoom(2);
        this.graph.center();
      }
    },
    zoomIn() {
      this.graph.zoom({ level: this.graph.zoom() * 1.1 });
    },
    zoomOut() {
      this.graph.zoom({ level: this.graph.zoom() / 1.1 });
    }
  }
};
</script>

<style>
#cy {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.line1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: gray;
}

node {
  background-color: green;
}
</style>
