<template>
  <v-container>
    <v-progress-linear v-if="uploading" indeterminate color="indigo">
    </v-progress-linear>
    <VueFileAgent
      ref="vueFileAgent"
      :multiple="false"
      :deletable="!uploading"
      :meta="true"
      :accept="exts.join(',')"
      :maxSize="maxSize"
      :helpText="'Choose files'"
      :errorText="errorText"
      @select="filePicked($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    ></VueFileAgent>
  </v-container>
</template>

<script>
import { eventBus } from "../../main";
export default {
  props: {
    label: { type: String },
    exts: { type: Array },
    fileType: { type: String },
    source: { type: String },
    icon: { type: Boolean },
    input: { type: Object },
    maxSize: { type: Number, default: 5000000000 }, //Default max is 5GB
    workflowUrl: { type: String }
  },
  data() {
    return {
      uploading: false,
      fileName: "",
      fileRecords: [],
      fileRecordsForUpload: []
    };
  },
  computed: {
    errorText() {
      return {
        type: `Invalid file type. Only extensions with ${this.exts.join(
          ", "
        )} Allowed`,
        size: `Files should not exceed ${this.maxSize} in size`
      };
    }
  },
  methods: {
    fileDeleted(fileRecord) {
      var fileName = this.label;
      eventBus.$emit("fileDeleted", fileName);
      var i = this.fileRecords.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecords.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        console.log("File still in queue");
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        console.log("File not in queue. deleting...");
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
      }
    },
    async filePicked(event) {
      let file = event[0].file;

      this.uploading = true;
      if (!file) {
        this.uploading = false;
        this.$emit("filePicked", {
          status: 404,
          message: "no file was uploaded",
          info: this.input
        });
        // this.$emit("filePicked", {
        //  status: 404,
        //   message: "no file was uploaded"
        // });

        return;
      }

      console.log("File size: ", file.size);
      if (file.size > this.maxSize) {
        this.uploading = false;
        this.$emit("filePicked", {
          status: 400,
          message:
            "file size is too large (max " +
            this.bytesToSize(this.maxSize) +
            ")",
          info: this.input
        });

        return;
      }

      let ext = this.getExt(file.name);
      console.log("File Extension: ", ext);
      if (this.exts.indexOf(ext) == -1) {
        this.uploading = false;
        this.$emit("filePicked", {
          status: 400,
          message: "file is not a valid file type",
          info: this.input
        });

        return;
      }

      // An uploaded file needs to be uploaded to a specific workflow run

      // First check if there is an existing workflow run created already (which is stored in the workflow state)
      // If there isn't, create a new workflow run first so that we can upload the file to it
      if (!this.$store.getters.workflow || !this.$store.getters.workflow.url) {
        const payload = {
          status: -2,
          workflow: this.workflowUrl
        };
        await this.$store.dispatch("postWorkflowRun", payload);
      }

      // Attach the workflow run URL to the payload prior to upload
      let payload = {
        name: file.name,
        description: this.label,
        filename: file.name,
        type: 0, // Meaning: "Input"
        mime_type: file.type || "application/octet-stream",
        run: this.$store.getters.workflow.url
      };

      this.$store
        .dispatch("postResource", payload)
        .then(response => {
          console.log("UPLOADING TO SIGNED URL...");
          return this.$store.dispatch("uploadFileToSignedUrl", {
            file: file,
            signed_url: response.signed_url,
            api_url: response.url
          });
        })
        .then(response => {
          console.log("DISPATCHED FILE");
          this.uploading = false;
          this.fileName = file.name;
          this.$emit("filePicked", { ...payload, file, info: this.input });
        })
        .catch(data => {
          console.error("Create Upload Exception in Error handling: ");
          console.error(data);
          this.uploading = false;

          //Send empty object
          this.$emit("filePicked", {
            status: data.status,
            message: "the server responded with an error",
            info: this.input
          });
        });
    },
    getExt(fileName) {
      return "." + fileName.split(".")[1].toLowerCase();
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }
  }
};
</script>
