<template>
  <div id="app">
    <v-app>
      <toolbar @logoClicked="toggleSideBar"></toolbar>
      <sidebar :sidebar="sidebar" v-if="!$route.meta.hideSideBar"></sidebar>

      <v-main>
        <transition>
          <v-container fluid class="pa-0">
            <router-view></router-view>
          </v-container>
        </transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Toolbar from "./components/Toolbar";
import Sidebar from "./components/Sidebar";
export default {
  name: "App",
  components: { Toolbar, Sidebar },
  data() {
    return {
      sidebar: true
    };
  },
  computed: {
    auth() {
      // console.log("Is Authenticated " + this.$store.getters.isAuthenticated);
      return this.$store.getters.isAuthenticated;
    }
  },
  mounted() {
    console.log("CREATED APP");
    // this.$store.dispatch("tryAutoLogin");
  },
  methods: {
    toggleSideBar() {
      this.sidebar = !this.sidebar;
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave {
  /*opacity: 1; opacity is default zero*/
}

.fade-leave-active {
  transition: opacity 1s;
  opacity: 0;
}

.slide-enter {
}

.slide-enter-active {
  animation: slide-in 1s ease-out forwards;
}

.slide-leave {
}

.slide-leave-active {
  animation: slide-out 1s ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(20px);
  }
}
</style>
