<template>
  <v-container grid-list-lg class="py-0">
    <v-row>
      <v-col class="py-0" sm="12" md="5" lg="4" xl="3">
        <v-text-field
          label="Search for a workflow"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="my-2"
        v-for="(item, i) in filteredList"
        :key="i"
        sm="12"
        md="6"
        lg="6"
        xl="4"
      >
      <v-tooltip bottom :disabled="item.available">
          <template v-slot:activator="{ on }">
            <div v-on="on">
        <workflow-card
          :item="item"
          @selected="selected($event)"
        ></workflow-card>
          </div>
          </template>
          <span>There are currently no workers available for this workflow</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkflowCard from "@/components/run/WorkflowCard";

export default {
  components: { WorkflowCard },
  props: { items: { type: Array, default: new Array() } },
  data() {
    return {
      search: ""
    };
  },
  methods: {
    selected(item) {
      this.$emit("selected", item);
    }
  },
  computed: {
    filteredList() {
      this.items.filter(post => {
        return post.label.toLowerCase().includes(this.search.toLowerCase());
      });
      return this.items.filter(post => {
        return post.label.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  }
};
</script>
