<template>
  <v-app-bar color="indigo lighten-2" dark fixed app clipped-left flat>
    <v-app-bar-nav-icon @click="logoClicked" dark></v-app-bar-nav-icon>

    <v-toolbar-title class="white--text ml-0">
      <v-img width="200px" :src="logo" @click="logoClicked" contain></v-img>
      <!-- <v-chip color="indigo" text-color="white" small class="pl-2"
            >AWF
          </v-chip> -->
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <template v-if="this.$store.getters.isAuthenticated">
      <v-tooltip bottom>
        <template slot="activator" slot-scope="{ on }">
          <v-btn icon v-on="on" @click="feedbackClicked">
            <v-icon>mdi-comment-question-outline</v-icon>
          </v-btn>
        </template>
        Give us feedback!
      </v-tooltip>
      <v-avatar v-if="image" size="36px" class="mx-2">
        <v-img :src="image"></v-img>
      </v-avatar>
      <strong v-if="!image">
        <span class="white--text font-weight-light subtitle-2">{{
          username
        }}</span>
      </strong>

      <v-tooltip bottom>
        <template slot="activator" slot-scope="{ on }">
          <v-btn @click="logout" icon v-on="on">
            <v-icon color="white">mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        Sign out
      </v-tooltip>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../assets/images/logos/AWF_Logo.png"),
      image: this.$store.state.profileImage
    };
  },
  mounted() {
    if (!this.image) {
      this.getProfileImg();
    }
  },
  computed: {
    username() {
      return this.$store.getters.userLabel;
    }
  },
  methods: {
    feedbackClicked() {
      return window.open(this.$store.state.feedback);
    },
    getProfileImg() {
      return this.$store
        .dispatch("getMyPhoto")
        .then(response => {
          this.image = response;
        })
        .catch(error => {
          console.error(error);
        });
    },

    logoClicked(event) {
      return this.$emit("logoClicked", event);
    },
    logout() {
      return this.$store.dispatch("logout");
    }
  }
};
</script>
