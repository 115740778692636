<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col sm="12">
        <nav-header
          :title="header.title"
          :subtitle="header.subtitle"
          :btns="header.btns"
        ></nav-header>
      </v-col>

      <v-col sm="12">
        <v-container>
          <v-row>
            <v-col sm="12">
              <v-card flat class="transparent">
                <v-card-title>
                  <span class="headline">Getting Started</span>
                </v-card-title>
                <v-card-text class="py-0">
                  <vue-markdown>{{ docs.intro }}</vue-markdown>
                </v-card-text>
              </v-card>
              <v-col class="mt-4" sm="12">
                <p class="text-sm-center">
                  <v-btn color="indigo" @click="toDevDocs" dark
                    >To Swagger Docs
                  </v-btn>
                </p>
              </v-col>

              <template v-for="paragraph in docs.endpoints">
                <v-container class="pa-0" :key="paragraph.title">
                  <v-card class="mt-2 mb-4 transparent" flat>
                    <v-card-title>
                      <span class="headline">{{ paragraph.title }}</span>
                    </v-card-title>
                  </v-card>
                  <v-card
                    class="my-2 transparent"
                    v-for="section in paragraph.sections"
                    :key="section.description"
                    flat
                  >
                    <v-card-text class="py-0 body-2">
                      <p
                        class="subtitle-1 font-weight-bold"
                        v-if="section.subtitle"
                      >
                        {{ section.subtitle }}
                      </p>
                      <vue-markdown>{{ section.description }}</vue-markdown>

                      <api-example
                        :request="section.request"
                        :url="section.url"
                      ></api-example>

                      <v-container class="py-0">
                        <v-row>
                          <template
                            v-if="section.headers && section.headers.length > 0"
                          >
                            <v-col sm="12">
                              <span class="body-2 grey--text font-weight-medium"
                                >Headers</span
                              >
                            </v-col>

                            <v-col
                              sm="12"
                              v-for="header in section.headers"
                              :key="header.name"
                            >
                              <span class="body-2">
                                <vue-markdown :source="formatHeader(header)">
                                </vue-markdown>
                              </span>
                            </v-col>
                          </template>
                          <template
                            v-if="section.params && section.params.length > 0"
                          >
                            <v-col sm="12" class="mt-4">
                              <span class="body-2 grey--text font-weight-medium"
                                >Parameters</span
                              >
                            </v-col>
                            <v-col
                              sm="12"
                              v-for="param in section.params"
                              :key="param.name"
                            >
                              <vue-markdown :source="formatHeader(param)">
                              </vue-markdown>
                            </v-col>
                            <v-col sm="12" class="mt-4">
                              <vue-markdown>{{ section.footer }}</vue-markdown>
                            </v-col>
                          </template>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
import ApiExample from "../components/docs/ApiExample.vue";

import VueMarkdown from "vue-markdown";

export default {
  components: { NavHeader, ApiExample, VueMarkdown },
  data() {
    return {
      header: {
        title: "Documentation",
        subtitle: "For developers",
        btns: [
          {
            icon: "mdi-trending-up",
            tooltip: "See running workflows",
            nav: "/status"
          },
          {
            icon: "mdi-help-circle-outline",
            tooltip: "What does AWF do?",
            nav: "/faq",
            flat: true
          }
        ]
      },
      docs: {
        intro:
          "AWF provides a REST API so that you can intgrate AWF as part of your automation solution. Through this API, you can submit jobs to any workflow with a simple HTTP request. This section will provide simple examples that show how to GET information about workflows, and POST jobs to a particular workflow.\n\n For more detailed information on the API endpoints, the button below will link to the Swagger API documentation page.",
        endpoints: [
          {
            title: "Get Authorization Token",
            sections: [
              {
                description:
                  "For security all API requests need to authenticate with the AWF API backend. In order to do this you will need to authenticate with [Azure AD](https://docs.microsoft.com/en-us/graph/auth/auth-concepts?view=graph-rest-1.0). First you will need to [register your app](https://docs.microsoft.com/en-us/graph/auth-register-app-v2#:~:text=In%20the%20left%2Dhand%20navigation,to%20users%20of%20the%20app.) and get the `TENANT_ID` to authenticate against the correct endpoint.",
                url:
                  "https://login.microsoftonline.com/organizations/{TENANT_ID}",
                request: "POST",
                params: [
                  {
                    name: "client_id",
                    description:
                      "The client id obtained from registering the app on Azure App registrations"
                  }
                ],
                footer:
                  "This will return an `AUTH_TOKEN` that you will need to add to the header of every subsequent request."
              }
            ]
          },
          {
            title: "Get information about a workflow",
            sections: [
              {
                description:
                  "In order to retrieve a list of all available workflows simply run: ",
                url: this.$store.state.api + "workflow/",
                request: "GET",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ],
                footer:
                  "For each workflow in the response there will be a `workflow_url` attribute that contains the API link to the workflow. A GET request can be made directly to this url in order to access more detail about the workflow."
              }
            ]
          },
          {
            title: "Submitting a job to a workflow",
            sections: [
              {
                subtitle: "Initialize a workflow run",
                description:
                  "In order to run a workflow you will first need create a new workflow run. This allows you to attach information about a workflow prior to running it.",
                url: this.$store.state.api + "run/",
                request: "POST",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ],
                params: [
                  {
                    name: "workflow_url",
                    description: "The URL of the workflow that needs to be run."
                  },
                  {
                    name: "status",
                    description:
                      "This is the run status of the workflow. Set to **-2** to initialize a new workflow run."
                  }
                ],
                footer:
                  "This will return a workflow `RUN_ID`. Keep this id, since it will be used in subsequent API calls to add information to the workflow run"
              },
              {
                subtitle: "Upload a file",
                description:
                  "Usually a workflow will require an input file to run the workflow on. This is done by first requesting a signed URL to upload the file to.",
                url: this.$store.state.api + "resource/",
                request: "POST",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ],
                params: [
                  {
                    name: "name",
                    description: "The name of the file"
                  },
                  {
                    name: "description",
                    description: "A desciption of what the file is"
                  },
                  {
                    name: "filename",
                    description: "The name of the file"
                  },
                  {
                    name: "type",
                    description:
                      "The file type. Set to **0** to indicate it is an input file"
                  },
                  {
                    name: "mime_type",
                    description: "The file MIME type"
                  },
                  {
                    name: "run",
                    description: "The `RUN_ID` which the file pertains to"
                  }
                ]
              },
              {
                description:
                  "The signed url will be an Amazon S3 URL and will look something like",
                url:
                  "https://s3.amazonaws.com/{{S3_BUCKET}}/{{path}}?AWSAccessKeyId={{S3_ACCESS_KEY_ID}}&Expires={{expire_date}}&Signature={{signature}}",
                request: "PUT",
                headers: [
                  {
                    name: "Content-Type",
                    description:
                      "The file MIME type to identify the type of file that is being uploaded"
                  }
                ],
                params: [
                  {
                    name: "file",
                    description: "The file to upload"
                  }
                ]
              },
              {
                subtitle: "Run the workflow",
                description:
                  "Submit a job to the queue, passing in the workflow `RUN_ID` as an argument, as well as additional information about the workflow run.",
                url: this.$store.state.api + "run/",
                request: "PATCH",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ],
                params: [
                  {
                    name: "workflow",
                    description: "The `RUN_ID` of the workflow run"
                  },
                  {
                    name: "status",
                    description:
                      "The status code of the workflow run. Use **-1** to submit the workflow to the queue"
                  },
                  {
                    name: "arguments",
                    description: "A list of workflow arguments"
                  }
                ]
              }
            ]
          },
          {
            title: "Checking the workflow status",
            sections: [
              {
                description:
                  "Check the status of a running workflow to see if it is still running or it has completed. Use the `RUN_ID` to retrieve the information about your run. Completed runs have a status code of 1 if successful and status codes of 2 and higher an error occured during the workflow.",
                url: this.$store.state.api + "run/{RUN_ID}/",
                request: "GET",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ]
              }
            ]
          },
          {
            title: "Download workflow results",
            sections: [
              {
                description:
                  "After the workflow run completes, you can get a list of results files (resources) that are available to download using the `RUN_ID`:",
                url: this.$store.state.api + "privatedata/resource/",
                request: "GET",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ],
                params: [
                  {
                    name: "run_id",
                    description: "The `RUN_ID` of the workflow run"
                  }
                ],
                footer:
                  "Each resource will have a `url` attribute that contains the download link to the resource."
              },
              {
                description:
                  "You can download the resource using that `url` attribute, which will look something like: ",
                url:
                  this.$store.state.api + "privatedata/resource/{RESOURCE_ID}",
                request: "GET",
                headers: [
                  {
                    name: "Authorization",
                    description:
                      "Add the acquired Bearer token with the format **Bearer azuread-oauth2 {AUTH_TOKEN}**"
                  }
                ]
              }
            ]
          }

          // authToken:
          //   "All API requests need to authenticate with the AWF API backend.",
          // getWorkflows:
          //   "In order to retrieve a list of all available workflows simply run: ",
          // getWorkflow:
          //   "Use the workflow `id` to get more detailed information about the workflow: ",
          // postRun:
          //   "Submit a job to the queue, passing in the workflow `id` as an argument, as well as additional information about the workflow run.",
          // getStatus:
          //   "Check the status of a running workflow to see if it is still running or it has completed. Completed runs have a status code of 1 if successful and status codes of 2 and higher an error occured during the workflow.",
          // getResource:
          //   "After detail about the workflow has been received, find the resource `id` and use this to retrieve the resource:"
        ]
      }
    };
  },
  methods: {
    toDevDocs() {
      //location.href = this.$store.state.docs;
      window.open(this.$store.state.docs);
    },
    formatHeader(header) {
      return "- `" + header.name + ":` " + header.description;
    }
  },
  computed: {
    // introduction() {
    //   return marked(this.docs.intro);
    // },
    // getWorkflowDoc() {
    //   return marked(this.docs.getWorkflow);
    // },
    getWorkflows() {
      return this.$store.state.api + "workflow/";
    },
    getWorkflow() {
      return this.$store.state.api + "workflow/{id}";
    },
    getStatus() {
      return this.$store.state.api + "checkstatus/{status_id}";
    },
    postRun() {
      return this.$store.state.api + "queue/";
    },
    getResource() {
      return this.$store.state.api + "privatedata/resources/{id}";
    }
  }
};
</script>
