<template>
  <v-container grid-list-md fluid class="pa-0">
    <v-row no-gutters>
      <v-col v-if="!loaded && loadError" sm="10">
        <v-alert
          colored-border
          border="top"
          :value="loadError"
          color="error"
          icon="warning"
          outlined
        >
          {{ errorMsg }}
        </v-alert>
      </v-col>
      <v-col v-else sm="12">
        <v-row no-gutters>
          <v-col sm="4">
            <template v-if="loaded">
              <v-card flat v-if="runs.length == 0">
                <v-card-text>
                  You currently do not have any workflows.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row justify="center" align="center">
                    <v-col>
                      <v-btn to="/run" color="indigo" dark
                        >Run a Workflow</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <awf-status-list
                v-else
                :items="runs"
                @fetchRuns="getRuns"
              ></awf-status-list>
            </template>

            <v-col v-else sm="12">
              <p class="text-center">
                <loader :height="100"></loader>
              </p>
            </v-col>
          </v-col>
          <v-col sm="8">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AwfStatusList from "../components/status/AwfStatusList.vue";
import Loader from "../components/Loader.vue";

export default {
  components: { Loader, AwfStatusList },
  data() {
    return {
      header: {
        title: "Workflow status",
        subtitle: "Check how your workflows are progressing",
        btns: [
          {
            icon: "mdi-plus",
            tooltip: "Run a workflow",
            nav: "/run"
          },
          {
            icon: "mdi-help-circle-outline",
            tooltip: "What does AWF do?",
            nav: "/faq",
            flat: true
          }
        ]
      },
      runs: [],
      loaded: false,
      loadError: false,
      errorMsg:
        "Could not connect to the AWF server. Please check your internet connection and try again"
    };
  },
  created() {
    this.getRuns();
  },
  methods: {
    async getRuns() {
      this.loaded = false;
      this.loadError = false;
      this.runs = await this.$store.dispatch("getWorkflowRuns");

      this.loaded = true;
      if (!this.runs) this.loadError = true;

      return this.runs;
    }
  }
};
</script>
