<template>
  <v-container fluid class="pa-0">
    <v-container fluid v-if="e1 == 1" class="mt-4">
      <nav-header
        :title="header.title"
        :subtitle="header.subtitle"
        :btns="header.btns"
      ></nav-header>
    </v-container>
    <v-col v-if="!loaded">
      <v-container class="pa-0" :style="centerScreen" fluid>
        <v-row align="center" justify="center" :style="centerScreen">
          <v-col sm="1">
            <loader></loader>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-row justify="center" no-gutters v-else>
      <v-col sm="12">
        <v-alert
          colored-border
          border="top"
          v-if="alert"
          v-model="alert"
          dismissible
          outlined
          type="danger"
          >{{ alertMessage }}</v-alert
        >
        <v-stepper
          v-model="e1"
          class="transparent"
          style="box-shadow: none; color: #fff"
        >
          <v-stepper-items>
            <v-stepper-content step="1" class="py-0">
              <v-row row fill-height>
                <v-col class="py-0" sm="12">
                  <awf-workflows
                    :items="workflows"
                    @hover="workflow = $event"
                    @selected="selectedWorkflow($event)"
                  ></awf-workflows>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-card class=" transparent" flat v-if="workflow.loaded">
                <v-toolbar color="grey lighten-4" flat>
                  <v-toolbar-title class>
                    <v-btn icon text @click="back()">
                      <v-icon color="indigo">mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-avatar class="mx-2">
                      <img :src="getDisciplineIcon(workflow.discipline)" />
                    </v-avatar>
                    <span class="title font-weight-light">
                      {{ workflow.label }}
                    </span>
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-tooltip top>
                    <template slot="activator" slot-scope="{ on }">
                      <v-btn
                        dark
                        color="indigo lighten-2"
                        v-on="on"
                        :href="workflow.documentation_url"
                        target="_blank"
                      >
                        <v-icon left>mdi-book-open-variant</v-icon>
                        Docs
                      </v-btn>
                    </template>
                    <span>See Documentation for this workflow</span>
                  </v-tooltip>
                </v-toolbar>
                <v-row justify="center">
                  <v-col md="12" lg="5" xl="4">
                    <v-card flat>
                      <v-card-text>
                        <vue-markdown
                          :source="workflow.documentation"
                        ></vue-markdown>
                      </v-card-text>
                    </v-card>

                    <v-card flat>
                      <v-card-title>
                        😙 Try it out!
                      </v-card-title>
                      <v-card-text>
                        If you are unfamiliar with how this workflow works,
                        we've prepared a demo version that you can try out. Just
                        click the button below!
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="orange" block dark @click="testWorkflow">
                          Run Example Workflow
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col
                    class="py-0"
                    md="12"
                    lg="7"
                    xl="8"
                    v-if="workflow.config_json"
                  >
                    <awf-parameters
                      v-if="e1 == 2"
                      :workflow="workflow"
                      @submit="runWorkflow($event)"
                      @test="testWorkflow()"
                    ></awf-parameters>
                  </v-col>
                </v-row>
              </v-card>
              <v-col v-if="!workflow.loaded">
                <v-container class="pa-0" :style="centerScreen" fluid>
                  <v-row align="center" justify="center" :style="centerScreen">
                      <loader :height="200"></loader>
                  </v-row>
                </v-container>
              </v-col>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavHeader from "@/components/NavHeader.vue";
import Loader from "@/components/Loader.vue";
import AwfWorkflows from "@/components/run/AwfWorkflows";
import AwfParameters from "@/components//run/AwfParameters";

import VueMarkdown from "vue-markdown";

import { mapGetters } from "vuex";

export default {
  components: {
    Loader,
    NavHeader,
    AwfWorkflows,
    AwfParameters,
    VueMarkdown,
    Loader
  },
  data() {
    return {
      header: {
        title: "Run a workflow",
        subtitle: "Select a preconfigured workflow to run",
        btns: [
          {
            icon: "mdi-trending-up",
            tooltip: "See running workflows",
            nav: "/status"
          },
          {
            icon: "mdi-help-circle-outline",
            tooltip: "What does AWF do?",
            nav: "/faq",
            flat: true
          }
        ]
      },
      e1: 1,
      alert: false,
      alertMessage: "",
      loaded: false,
      show: false,
      jobNumbers: [],
      workflow: {},
      jobNumber: "",
      dataset: "",
      workflows: [],
      token: null,
      offlineTheshold: 3600 //Threshold to consider offline (in seconds) - got from views/Admin.vue
    };
  },
  mounted() {
    // Check Vue store if workflows have been fetched already
    if (this.$store.state.workflows && this.$store.state.workflows.length > 0) {
      this.workflows = this.$store.state.workflows;
      this.loaded = true;
      return;
    }

    Promise.all([
      this.$store.dispatch("getWorkflows"),
      this.$store.dispatch("getWorkers")
    ])
      .then(([workflows, workers]) => {

        //Move default workflow to the front of the array
        let first = "tutorial"
        workflows.sort((a, b) => {
          return a.slug == first ? -1 : b.slug == first ? 1 : 0;
        });

        // find which workflows have available workers
        let availableProfiles = [];
        workers.forEach(worker => {
          if (this.isOnline(worker.last_heartbeat)) {
            availableProfiles.push(...worker.profiles);
          }
        });
        const updatedWorkflows = workflows.map(wf => {
          return {
            ...wf,
            available: availableProfiles.includes(wf.profile)
          };
        });
        this.loaded = true;
        this.workflows = updatedWorkflows
        this.$store.commit("storeWorkflows", this.workflows);
      })
      .catch(err => {
        console.error(err);
      });
  },
  computed: {
    ...mapGetters(["centerScreen"])
  },
  methods: {
    isOnline(lastHeartBeat) {
      // method taken from views/Admin.vue
      if (!lastHeartBeat) return false;
      let ts = new Date(lastHeartBeat);
      let diff = (new Date() - ts) / 1000; //Divide by 1000 to convert to seconds
      if (Math.abs(diff) < this.offlineTheshold) return true;
      else false;
    },
    back() {
      this.e1 = 1;

      //Clear all inputs
      this.workflow.arguments.forEach(arg => {
        if (arg) arg.value = null;
      });
      this.jobNumbers = [];
    },
    getDisciplineIcon(discipline) {
      return this.$store.getters.getDisciplineIcon(discipline);
    },
    selectedWorkflow(workflow) {
      this.workflow = {loaded : false}
      this.$store
        .dispatch("getWorkflow", workflow.url)
        .then(
          response => {
            this.workflow = {
              ...response,
              loaded : true
            }
          },
          error => {
            console.error("Workflow load error");
            console.error(error);
          }
        )
        .catch(data => {
          console.error(data);
        });
      // Clear any workflow runs from the cache if any had been created previously
      if (this.$store.getters.workflow) this.$store.commit("removeRun");

      this.e1++;
    },
    async runWorkflow(event) {
      var payload = {
        workflow: this.workflow.url,
        status: -1,
        arguments: event.arguments,
        job_number: event.job_number,
        object: event.object
      };

      // Send request to run workflow
      let run;
      if (this.$store.getters.workflow.url) {
        run = await this.$store.dispatch("patchWorkflowRun", {
          runUrl: this.$store.getters.workflow.url,
          payload
        });
      } else {
        run = await this.$store.dispatch("postWorkflowRun", payload);
      }

      // If there was an error with the request alert the user
      if (!run || !run.id) {
        this.alert = true;
        this.alertMessage =
          "Failed to run workflow, please check your internet connection.";
        return;
      }

      // Clear the workflow run from the cache
      this.$store.commit("removeRun");

      //If successful route to workflow run view
      this.$router.push("/status/run/" + run.id);
    },
    async testWorkflow() {
      let runName = "Demo " + this.workflow.label + " workflow";

      var payload = {
        workflow: this.$store.state.api + "workflow/system-test/",
        status: -1,
        arguments: "workflow=" + this.workflow.slug,
        job_number: "27334593",
        object: runName
      };

      let run = await this.$store.dispatch("postWorkflowRun", payload);

      // If there was an error with the request alert the user
      if (!run || !run.id) {
        this.alert = true;
        this.alertMessage =
          "Failed to run workflow, please check your internet connection.";
        return;
      }

      // Clear the workflow from the cache
      this.$store.commit("removeRun");

      //If successful route to workflow run view
      this.$router.push("/status/run/" + run.id);
    }
  }
};
</script>
