<template>
  <v-dialog v-model="dialog" width="500" persistent :retain-focus="false">
    <v-card>
      <v-card-title dark class="headline orange lighten-2 white--text">
        {{ param.label }}
      </v-card-title>
      <v-card-text>
        <v-container class="px-0" justify="center">
          <v-col class="py-0" cols="12">What is this parameter?</v-col>

          <v-col class="pt-0" cols="12">
            <span class="body-1">{{ param.description }}</span>
          </v-col>
          <v-col
            cols="11"
            v-show="param.type == 'string' && param.hidden != true"
          >
            <v-text-field
              @change="argChanged(param)"
              v-model="param.value"
              :ref="param.label"
              :rules="requiredRules"
              :label="param.label"
              :placeholder="param.default"
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            v-if="
              param.type == 'number' && !param.options && param.hidden != true
            "
          >
            <v-text-field
              v-model="param.value"
              @change="argChanged(param)"
              :ref="param.label"
              :rules="numberRules"
              :label="param.label"
              :placeholder="String(param.default)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            v-else-if="Array.isArray(param.properties) && param.hidden != true"
          >
            <v-select
              v-model="param.value"
              @change="argChanged(param)"
              :rules="requiredRules"
              :ref="param.label"
              :label="param.label"
              :items="param.properties"
              item-text="label"
              item-value="value"
              :placeholder="param.value"
            ></v-select>
          </v-col>
          <v-col
            cols="11"
            v-else-if="param.type == 'boolean' && param.hidden != true"
          >
            <v-switch
              v-model="param.value"
              :ref="param.label"
              @change="argChanged(param)"
              :label="param.label"
            ></v-switch>
          </v-col>
          <v-col
            cols="11"
            justify="center"
            ma-0
            pa-0
            :key="'f' + param.label"
            v-else-if="param.type == 'file'"
          >
            <v-progress-circular
              v-if="uploading"
              indeterminate
              color="indigo"
            ></v-progress-circular>
            <template v-else>
              <!-- <v-alert colored-border border="top" :value="alert" type="error">
                {{ alertMessage }}
              </v-alert> -->

              <v-snackbar v-model="alert" color="red" right bottom>
                {{ alertMessage }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="alert = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>

              <v-row justify="start" align="center">
                <v-col cols="4" pa-0>
                  {{ param.label }}
                  <v-btn
                    color="orange"
                    dark
                    outlined
                    v-if="param.demo_signed_url"
                    @click="download(param.demo_signed_url)"
                  >
                    <v-icon left dark> mdi-cloud-download </v-icon> Example
                  </v-btn>
                </v-col>
                <v-col cols="8" pa-0>
                  <file-upload
                    @change="argChanged(param)"
                    v-model="param.value"
                    :ref="param.label"
                    :label="param.label"
                    :exts="
                      param.properties && 'extension' in param.properties
                        ? param.properties.extension
                        : []
                    "
                    :fileType="param.type"
                    :workflowUrl="workflowUrl"
                    :source="username()"
                    :icon="uploadIcon"
                    :input="param"
                    @filePicked="fileSelected($event)"
                  ></file-upload>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="indigo" text @click="acceptClicked($event)">
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "../upload/FileUpload.vue";
import { eventBus } from "../../main";
import { mapGetters } from "vuex";

export default {
  components: {
    "file-upload": FileUpload
  },
  props: {
    dialog: { type: Boolean, default: false },
    param: { type: Object },
    workflowUrl: { type: String }
  },
  data() {
    return {
      allParameters: [],
      requiredRules: [v => !!v || "Field is required"],
      numberRules: [
        v => !!v || "Field is required",
        v => !isNaN(v) || "The value must be a number"
      ],
      uploadIcon: true,
      valid: false,
      alert: false,
      uploading: false,
      alertMessage: "",
      noFileMessage: "Please select a file to upload",
      invalidFileMessage: "Please select a valid file type",
      uploadFailedMessage: "Upload failed because "
    };
  },
  created() {
    eventBus.$on("fileDeleted", this.fileDeleted);
  },
  computed: {
    fileRequired() {
      return this.param.type === "file";
    }
  },
  methods: {
    ...mapGetters(["username"]),

    argChanged(param) {
      //eventBus.$emit("argChanged", param);
    },
    acceptClicked() {
      //If the field is invalid, then run the form validation to indicate it to the user

      let input_param = this.$refs[this.param.label];
      // Validate that input is correct
      if (input_param && input_param.value && !input_param.valid) {
        input_param.form.validate();
        return;
      }

      //If file is required, check that file was added to param
      if (this.fileRequired && input_param.value && !input_param.file) {
        this.alert = true;
        this.alertMessage = this.noFileMessage;
        return;
      }

      //Otherwise update the parameter and close dialog
      eventBus.$emit("argChanged", this.param);
      this.$emit("close");
    },
    async download(resourceUrl) {
      window.open(resourceUrl);
    },
    fileSelected(event) {
      this.alert = false;

      //Alert user if no file was selected (event will be null)
      if (event == null || event.status == 404) {
        this.alert = true;
        this.alertMessage = this.noFileMessage;
        return;
      }

      //An invalid response from the server would be an empty object
      if (event.status >= 400 || Object.keys(event).length == 0) {
        this.alert = true;
        this.alertMessage = this.uploadFailedMessage + event.message;
        return;
      }

      //Update parameter info
      this.param.value = event.name;
      this.param.file = event;

      //Notify argument was updated
      eventBus.$emit("argChanged", this.param);
    },
    fileDeleted(label) {
      if (this.param && this.param.value) {
        this.param.value = null;
        this.param.file = null;

        eventBus.$emit("argChanged", this.param);
      }
    }
  }
};
</script>
