<template>
  <v-container class="pa-0" :style="centerScreen" fluid>
    <v-row>
      <v-col v-if="loaded">
        <v-container class="py-0">
          <awf-header
            v-if="run && workflow"
            :name="run.object"
            :instance="workflow.label"
            :discipline="workflow.discipline"
            @refresh="getWorkflowRun"
            @toggleLog="logView = $event"
          ></awf-header>

          <v-row>
            <template v-if="!logView">
              <v-col class="pl-6 py-0" sm="12" md="6" lg="6">
                <v-row>
                  <v-col sm="12">
                    <awf-detail-status
                      :runId="runId"
                      :statusCode="run.status"
                      :jobNumber="run.job_number"
                      :status="run.status_display"
                      :startTime="run.date_created"
                      :latestTime="run.date_updated"
                    ></awf-detail-status>
                  </v-col>

                  <v-col sm="12" class="py-0">
                    <awf-files
                      icon="mdi-cloud-upload-outline"
                      title="Input Files"
                      :items="getResultsFiles('input')"
                    ></awf-files>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <awf-files
                      icon="mdi-cloud-download-outline"
                      title="Results"
                      :items="getResultsFiles('output')"
                    ></awf-files>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="6" class="pa-0">
                <v-col sm="12">
                  <v-card outlined class="pa-0" :style="fullHeight">
                    <workflow-diagram
                      v-if="workflow"
                      :submitted="true"
                      :inputs="inputs"
                      :workflowConfig="workflow.config_json"
                    ></workflow-diagram>
                  </v-card>
                </v-col>
              </v-col>
            </template>
            <template v-else>
              <v-col sm="8">
                <awf-log
                  :log="run.stdout"
                  :error="run.stderr"
                  :workerName="worker.label"
                  :workerLocation="worker.location"
                ></awf-log>
              </v-col>
              <v-col sm="4">
                <awf-files
                  title="Log files"
                  :items="getResultsFiles('log')"
                ></awf-files>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-else-if="loadError">
        <v-container class="py-0">
          <v-row align="center" justify="center" :style="centerScreen">
            <v-alert
              colored-border
              border="top"
              :value="loadError"
              color="error"
              icon="warning"
              outlined
            >
              {{ errorMsg }}
            </v-alert>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-else>
        <v-container class="pa-0" fluid v-if="runId">
          <v-row align="center" justify="center" :style="centerScreen">
            <loader :height="200"></loader>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from "../main";
import { mapGetters } from "vuex";

import AwfHeader from "../components/detail/AwfHeader.vue";
import AwfLog from "../components/detail/AwfLog.vue";
import AwfFiles from "../components/detail/AwfFiles.vue";
import AwfDetailStatus from "../components/detail/AwfDetailStatus.vue";

import WorkflowDiagram from "../components/workflows/WorkflowDiagram.vue";
import Loader from "../components/Loader.vue";

export default {
  name: "Detail",
  components: {
    AwfHeader,
    AwfLog,
    AwfFiles,
    AwfDetailStatus,
    WorkflowDiagram,
    Loader
  },
  data() {
    return {
      config: {},
      run: null,
      workflow: null,
      worker: null,
      logView: false,
      loaded: false,
      loadError: false,
      timer: null,
      errorMsg:
        "Could not connect to the AWF server. Please check your internet connection and try again"
    };
  },
  computed: {
    ...mapGetters(["centerScreen", "fullHeight"]),
    runId() {
      if (!this.$route.params.id) return;

      return Number(this.$route.params.id);
    },
    inputs() {
      if (
        !this.run ||
        !this.workflow ||
        Object.keys(this.run).length === 0 ||
        Object.keys(this.workflow).length === 0
      )
        return [];

      let inputs = [];
      this.workflow.arguments.forEach(arg => {

        arg.value = this.run.arguments[arg.name];
        inputs.push(arg);
      });
      return inputs;
    }
  },

  watch: {
    runId() {
      if (!this.runId) return;

      return this.getWorkflowRun(this.runId);
    },
    run() {
      if (!this.run || Object.keys(this.run).length === 0) return;

      //Remove newlines from string
      let config = this.run.workflow__config.replace(/(\r\n|\n|\r)/gm, "");
      config = config.replace(/\s+/g, "");
      let parser = new DOMParser();
      let configXml = parser.parseFromString(config, "application/xml");

      config = this.xmlToJson(configXml);
      config = config.workflow.activity.module;

      if (!Array.isArray(config)) this.config = [config];
      else this.config = config;
    }
  },
  created() {
    this.getWorkflowRun(this.runId);
    eventBus.$on("delete", this.deleteWorkflowRun);
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    async getWorkflowRun(runId) {
      this.loaded = false;
      this.loadError = false;

      // Reset state
      let worker = { label:undefined, location:undefined}
      this.run = null;
      this.workflow = null;
      this.worker = worker;
      this.cancelAutoUpdate();

      try {
        // Get workflow run info
        await this.getRun();

        if ("worker" in this.run && this.run.worker) {
          await this.getWorker();
        }

        // Get workflow info
        if ("workflow" in this.run) {
          await this.getWorkflow();
          eventBus.$emit("workflowUpdated", this.workflow);
        }

        // Autorefresh workflow run details
        this.timer = setInterval(this.getRun, 10000);
      } catch (e) {
        console.error(e);
        this.loadError = true;
      } finally {
        this.loaded = true;
      }
    },
    async getRun() {
      // Check if run data already exists, and don't run an update if the run has already completed
      if (this.run && Object.keys(this.run).length > 0 && this.run.status >= 2)
        return this.run;

      this.run = await this.$store.dispatch("getWorkflowRun", this.runId);
      if (!this.run) {
        this.loadError = true;
        console.error("Failed to fetch Workflow run");
      }

      return this.run;
    },
    async getWorker() {
      // Check if run data already exists, and don't run an update if the run has already completed
      if (this.worker && Object.keys(this.worker).length > 0)
        return this.worker;

      this.worker = await this.$store.dispatch("get", this.run.worker);
      if (!this.worker) {
        this.loadError = true;
        console.error("Failed to fetch Worker");
      }

      return this.worker;
    },
    async getWorkflow() {
      if (this.workflow && Object.keys(this.workflow).length > 0)
        return this.workflow;
      let workflow = await this.$store.dispatch("get", this.run.workflow);
      this.workflow = {
        ...this.workflow,
        ...workflow,
        config_json: workflow.config_json,
        arguments: workflow.arguments
      };

      if (!this.workflow) {
        this.loadError = true;
        console.error("Failed to fetch Workflow details");
      }
    },
    cancelAutoUpdate() {
      if (this.timer) clearInterval(this.timer);
    },
    getResultsFiles(search, exclude) {
      if (!this.run.resources) return [];
      var files = [];
      var type;
      var match;
      for (var i = 0; i < this.run.resources.length; i++) {
        type = this.run.resources[i].type_display;
        if (!type) continue;
        if (search && !type.toLowerCase().includes(search)) continue;
        if (exclude && type.toLowerCase().includes(exclude)) continue;
        files.push(this.run.resources[i]);
      }
      return files;
    },
    isLogFile(resourceType) {
      return resourceType.toLowerCase() === "log";
    },
    refreshClicked() {},
    deleteWorkflowRun() {
      var results = this.getResultsFiles(null, "log");

      this.$store
        .dispatch("deleteWorkflowRun", this.runId)
        .then(
          response => {
            eventBus.$emit("deleted", true);
          },
          error => {
            console.error(error);
            eventBus.$emit("deleted", false);
          }
        )
        .catch(error => {
          console.error(error);
          eventBus.$emit("deleted", false);
        });
    },
    // Changes XML to JSON
    xmlToJson(xml) {
      // Create the return object
      var obj = {};

      if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (var j = 0; j < xml.attributes.length; j++) {
            var attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
        for (var i = 0; i < xml.childNodes.length; i++) {
          var item = xml.childNodes.item(i);
          var nodeName = item.nodeName;
          if (typeof obj[nodeName] == "undefined") {
            obj[nodeName] = this.xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push == "undefined") {
              var old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(this.xmlToJson(item));
          }
        }
      }
      return obj;
    }
  }
};
</script>
