<template>
  <v-container class="pa-0">
    <v-card flat max-width="800">
      <v-toolbar dark flat dense :color="statusColor">
        <v-icon v-if="statusCode == 1" color="white"
          >mdi-alert-circle-outline</v-icon
        >
        <v-icon v-if="statusCode == 2" color="white"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-if="statusCode == 3" color="white"
          >mdi-alert-circle-outline</v-icon
        >

        <v-toolbar-title class="ml-4 subtitle-1">{{ status }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-row>
          <v-col sm="12" class="pt-0">
            <!-- <div class="text-xs-left grey--text">
              <strong>{{ status }}</strong>
            </div>-->

            <v-progress-linear
              v-if="running"
              class="my-0"
              :color="progressBar.color"
              :height="progressBar.height"
              :value="progressBar.value"
              :indeterminate="running"
            ></v-progress-linear>
          </v-col>

          <v-col class="pa-0" cols="6" lg="2" xl="3">
            <v-list two-line class="py-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="text-sm-center grey--text subtitle-2 mb-0">
                      Run ID
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="black--text subtitle-2  font-weight-light"
                  >
                    <p class="text-sm-center">
                      {{ runId }}
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="pa-0" cols="6" lg="3" xl="3">
            <v-list two-line class="py-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="text-sm-center grey--text subtitle-2 mb-0">
                      Job Number
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="black--text subtitle-2  font-weight-light"
                  >
                    <p class="text-sm-center">
                      {{ jobNumber }}
                    </p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="pa-0" cols="6" lg="4" xl="3">
            <v-list two-line class="py-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="text-sm-center grey--text subtitle-2 mb-0">
                      Submitted
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="black--text subtitle-2  font-weight-light"
                  >
                    <p class="text-sm-center">{{ getStartTime }}</p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="pt-0" cols="6" lg="3" xl="3">
            <v-list two-line class="py-0">
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="text-sm-center grey--text subtitle-2 mb-0">
                      {{ getStatusTitle }}
                    </p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="black--text subtitle-2 font-weight-light"
                  >
                    <p class="text-sm-center">{{ timeElapsed }}</p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    runId: Number,
    jobNumber: Number,
    statusCode: Number,
    status: String,
    startTime: String,
    latestTime: String
  },
  data() {
    return {
      progressBar: {
        value: 0,
        height: 15,
        color: "indigo",
        indeterminate: false
      },
      logToggle: false
    };
  },

  computed: {
    running() {
      return this.statusCode == 0;
    },
    completed() {
      return this.statusCode > 1;
    },
    statusColor() {
      //Successful status
      if (this.statusCode == 2) return "green lighten-2";
      else if (this.statusCode >= 3) return "red lighten-2";

      //For any other status code
      return "indigo lighten-2";
    },
    getStatusTitle: function() {
      if (this.completed) return "Took";
      else return "Time elapsed";
    },
    getStartTime: function() {
      var ts = new Date(this.startTime);
      var mm = ts.getMonth() + 1; // getMonth() is zero-based
      var dd = ts.getDate();
      return ts.toLocaleDateString() + " " + ts.toLocaleTimeString();
    },
    timeElapsed: function() {
      if (this.completed) var time = new Date(this.latestTime);
      else var time = new Date();

      var elapsed = time - new Date(this.startTime);
      var seconds = Math.floor(elapsed / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);
      var months = Math.floor(days / 30);
      if (seconds < 60) return seconds + " seconds";
      if (minutes < 60) return minutes + " minutes";
      if (hours < 24) return hours + " hours";
      if (days < 30) return days + " days";
      return months + " months";
    }
  },
  methods: {}
};
</script>
