<template>
  <v-card
    hover
    :min-width="width"
    :height="height"
    @click="selected(item)"
    :class="{ premium: isPremium, nonPremium: !isPremium }"
    :disabled="!item.available"
  >
    <v-card-title primary-title color="red lighten-2">
      <v-col>
        <v-list two-line>
          <v-list-item avatar>
            <v-list-item-avatar>
              <img :src="getDisciplineIcon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.label"></v-list-item-title>
              <v-list-item-subtitle>{{
                item.discipline.charAt(0).toUpperCase() +
                  item.discipline.slice(1)
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template slot="activator" slot-scope="{ on }">
                  <v-icon v-if="isPremium" v-on="on" color="#FFBF00"
                    >mdi-medal</v-icon
                  >
                </template>
                <span>{{ costMessage }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip top>
                <template slot="activator" slot-scope="{ on }">
                  <v-icon v-on="on" :color="healthColor">
                    {{ healthIcon }}</v-icon
                  >
                </template>
                <span>{{ healthMessage }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-col class="text-sm-left px-5 py-0">{{ item.description }}</v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: { item: { type: Object } },
  data() {
    return {
      height: 230,
      width: 300
    };
  },
  computed: {
    isHealthy() {
      if (this.item.health_status) return true;
      else return false;
    },
    healthColor() {
      if (this.isHealthy) return "green lighten-2";
      else return "red lighten-2";
    },
    healthIcon() {
      if (this.isHealthy) return "mdi-check-circle";
      else return "mdi-alert-circle";
    },
    healthMessage() {
      if (this.isHealthy) return "Workflow is ready to run";
      else
        return "Workflow didn't pass validation tests. Please use with caution!";
    },
    getDisciplineIcon() {
      return this.$store.getters.getDisciplineIcon(this.item.discipline);
    },
    isPremium() {
      return +this.item.unit_price > 0;
    },
    costMessage() {
      return `This workflow costs €${this.item.unit_price * 60} per minute`;
    }
  },

  methods: {
    hover(item) {
      this.$emit("hover", item);
    },
    selected(item) {
      this.$emit("selected", item);
    }
  }
};
</script>
<style scoped>
.premium {
  border: 2px solid #ffbf00 !important;
}
.nonPremium {
  border: 2px solid clear !important;
}
</style>
