<template>
  <v-container>
    <nav-header
      :title="header.title"
      :subtitle="header.subtitle"
    ></nav-header>

    <v-container v-bind="{ [`grid-list-${size}`]: true }">
      <v-row>
        <v-col v-for="worker in workerByStatus" :key="worker.id" sm="3">
          <v-card tile min-height="100px">
            <v-toolbar flat color="grey lighten-4" dense>
              <v-toolbar-title>{{ worker.label }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-chip
                v-if="isOnline(worker.last_heartbeat)"
                color="green lighten-2"
                text-color="white"
                small
              ></v-chip>
              <v-chip
                v-else
                color="red lighten-2"
                text-color="white"
                small
              ></v-chip>
            </v-toolbar>

            <v-card-text>
              <v-col class="py-0" style="text-align: start">
                <span class="grey--text">
                  {{ worker.location }}
                </span>
              </v-col>
              <v-col style="text-align: start">
                <v-chip
                  class="text-uppercase mr-2"
                  v-for="profile in worker.profiles"
                  :key="profile"
                  color="indigo lighten-2"
                  text-color="white"
                  small
                  >{{ profile }}</v-chip
                >
              </v-col>

              <v-col style="text-align: start">
                <v-icon color="indigo" class="mx-1">mdi-heart</v-icon>

                <span class="indigo--text caption"
                  >{{ timeElapsed(worker.last_heartbeat) }} ago</span
                >
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
export default {
  components: { NavHeader },
  data: () => ({
    header: {
      title: "Workers",
      subtitle: "These are your autobot workers that run your workflows",
      btns: [
        {
          icon: "mdi-plus",
          tooltip: "Run a workflow",
          nav: "/run"
        },
        {
          icon: "mdi-help-circle-outline",
          tooltip: "What does AWF do?",
          nav: "/faq",
          flat: true
        }
      ]
    },
    size: "lg",
    offlineTheshold: 3600, //Threshold to consider offline (in seconds)
    loading: false,
    workers: []
  }),
  computed: {
    workerByStatus() {
      if (!this.workers || this.workers.length == 0) return this.workers;
      console.log("WORKERS", [...this.workers]);

      let copy = [...this.workers];
      return copy.sort((a, b) => {
        let dateA = new Date(a.last_heartbeat);
        let dateB = new Date(b.last_heartbeat);
        if (dateA < dateB) return 1;
        else return -1;
      });
    }
  },
  methods: {
    isOnline(lastHeartBeat) {
      if (!lastHeartBeat) return false;
      let ts = new Date(lastHeartBeat);
      let diff = (new Date() - ts) / 1000; //Divide by 1000 to convert to seconds
      if (Math.abs(diff) < this.offlineTheshold) return true;
      else false;
    },
    timeElapsed(lastHeartBeat) {
      return this.$store.getters.timeElapsed(lastHeartBeat);
    }
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch("getWorkers")
      .then(
        response => {
          console.log(response);
          this.workers = response;
          this.loading = false;
        },
        error => {
          console.error("Worker load error");
          console.error(error);
          this.loading = false;
        }
      )
      .catch(data => {
        console.error(data);
        this.loading = false;
      });

    this.loading = false;
  }
};
</script>
