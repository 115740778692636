<template>
  <v-container class="px-0">
    <v-card dark>
      <v-container class="py-0">
        <v-card-title primary-title class="pa-0">
          <v-list class="pa-0">
            <v-list-item avatar>
              <v-list-item-avatar>
                <v-icon>mdi-laptop</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ title }} -
                  <span class="grey--text">{{ workerName }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list dense>
            <template v-if="logItems.length == 0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    There are no items in the log
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-virtual-scroll
              :items="logItems"
              height="600"
              item-height="30"
              v-else
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.timestamp">
                  <v-list-item-content>
                    <v-list-item-title v-if="item.type == 'log'">
                      <span class="grey--text mr-2"> {{ item.timestamp }}</span>
                      {{ item.log }}
                    </v-list-item-title>
                    <v-list-item-title v-if="item.type == 'error'">
                      <span class="grey--text mr-2">{{ item.timestamp }}</span>
                      <span class="red--text">{{ item.log }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    log: String,
    error: String,
    files: Array,
    workerName: String,
    workerLocation: String
  },
  data() {
    return {
      title: "Workflow Log"
    };
  },
  computed: {
    logs() {
      let logs = this.log.split(/(?:\r\n|\r|\n)/g);

      return this.parseLogItems("log", logs);
    },
    errors() {
      let errors = [];
      if (this.error != null) errors = this.error.split(/(?:\r\n|\r|\n)/g);

      return this.parseLogItems("error", errors);
    },
    logItems() {
      if (this.log == null) return [];

      let logItems = this.logs.concat(this.errors);

      // Sort items by timestamp
      logItems.sort((a, b) => (a.timestamp >= b.timestamp ? 1 : -1));

      return logItems;
    },
    logTitle() {
      return this.title + " - " + this.workerName;
    }
  },
  methods: {
    parseLogItem(l) {
      try {
        let parsed = l.split(":");
        let timestamp = new Date(parsed.slice(0, 3).join(":")).toISOString();
        let log = parsed.slice(3).join(":");
        if (log == "") return;

        // Return parsed log item
        return {
          timestamp: timestamp,
          log: log
        };
      } catch (e) {
        //If the parsing fails just return the log line as is
        return {
          timestamp: null,
          log: l
        };
      }
    },
    parseLogItems(type, items) {
      var parseLogItem = this.parseLogItem;
      let logItems = [];

      //Parse every log item and remove any that could not be parsed properly.
      let parsedItems = items
        .map(a => parseLogItem(a))
        .filter(a => a !== undefined);

      parsedItems.forEach((item, i) => {
        // If no timestamp was found, use the timestamp of the previous element
        if (!item.timestamp) {
          item.timestamp = logItems[i - 1].timestamp;
        }
        logItems.push({ type: type, ...item });
      });
      return logItems;
    }
  }
};
</script>
