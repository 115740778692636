<template>
  <v-card flat>
    <v-card-title>
      ⚡ Submitting a workflow run
    </v-card-title>
    <v-card-text>
      <p>
        You can run a workflow by using the interactive workflow diagram.
      </p>
      <p>Give your workflow run a name so that you can track it afterwards.</p>
      <v-alert text type="indigo">
        <p>
          Please also supply a Job Number with your workflow so that we can
          track which projects are using AWF.
        </p>
        <span class="orange--text"
          >💰 Only premium workflows will be billed to the Job Number.
        </span>
      </v-alert>

      <v-icon color="orange">
        mdi-checkbox-blank-circle-outline
      </v-icon>
      <span class="orange--text">Input Parameters</span>
      <p>
        You can fill in the input parameters of the workflow by clicking on the
        <span class="orange--text">yellow circles</span>. After you set the
        parameter, the circle will appear filled on the diagram.
      </p>
      <v-icon color="indigo">
        mdi-checkbox-blank-circle-outline
      </v-icon>
      <span class="indigo--text">Submit Button</span>
      <p>
        When all input parameters have been filled in, click on the
        <span class="indigo--text">purple circle</span> to submit your workflow
        run.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
