<template>
  <v-container>
    <nav-header
      :title="header.title"
      :subtitle="header.subtitle"
      :btns="header.btns"
    ></nav-header>

    <v-container>
      <v-card flat>
        <v-row>
          <v-col
            sm="6"
            md="4"
            lg="3"
            xl="2"
            v-for="user in users"
            :key="user.displayName"
          >
            <v-card hover max-width="250px" @click="sendEmail(user.mail)">
              <v-img
                v-if="user.photo"
                class="white--text"
                height="200px"
                contain
                :src="user.photo"
              >
              </v-img>

              <v-card-text>
                <v-row>
                  <v-col sm="12" class="pa-0">
                    <p class="title font-weight-light text-sm-center my-0">
                      {{ user.displayName }}
                    </p>
                  </v-col>
                  <v-col sm="12" class="pa-0">
                    <p class="subtitle-2 indigo--text text-sm-center">
                      {{ user.jobTitle }}
                    </p>
                  </v-col>
                  <v-col sm="12" class="pa-0">
                    <p
                      class="subtitle-2 grey--text text-sm-center font-weight-light mb-0"
                    >
                      {{ user.officeLocation }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions></v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
export default {
  components: { NavHeader },
  mounted() {
    var people = this.people;
    Object.keys(this.people).forEach(username => {
      this.$store
        .dispatch("getUser", username)
        .then(response => {
          let user = response;
          if (!user.photo)
            user.photo = people[user.userPrincipalName.toLowerCase()].photo;
          this.users.push(user);
        })
        .catch(error => {
          console.error(error);
        });
    });
  },
  data() {
    return {
      header: {
        title: "Contact Us",
        subtitle:
          "Please feel free to reach out to us if you have any questions or would like help to get started.",
        btns: [
          {
            icon: "mdi-plus",
            tooltip: "Run a workflow",
            nav: "/run"
          },
          {
            icon: "mdi-help-circle-outline",
            tooltip: "What does AWF do?",
            nav: "/faq",
            flat: true
          }
        ]
      },
      users: [],
      // TODO: Temporary solution until app permissions are approved with DTG
      people: {
        // TODO autopopulate
      }
    };
  },
  computed: {
    // sortedUsers() {
    //   return this.users.sort((a, b) => {
    //     a.surname > b.surname;
    //   });
    // },
    // sortedUsernames() {
    //   return this.users.sort((a, b) => {
    //     a.surname > b.surname;
    //   });
    //   }
  },
  methods: {
    sendEmail(email) {
      if (!email) return;
      window.open("mailto:" + email + "?subject=AWF Inquiry");
    }
  }
};
</script>
