<template>
  <v-navigation-drawer
    v-model="sidebar"
    value="false"
    mini-variant
    mini-variant-width="100"
    clipped
    fixed
    app
  >
    <v-list shaped>
      <template v-for="item in items">
        <!-- Component for navigating externally -->
        <v-list-item
          v-if="item.external"
          :color="color"
          :key="item.name"
          @click="toUrl(item.url)"
        >
          <v-list-item-title>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center pb-0">
                <v-icon> {{ item.icon }} </v-icon>
              </v-col>
              <v-col cols="12" class="text-center grey--text pt-0">
                <span class="subtitle-2">{{ item.name }}</span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>

        <!-- Component for nagivating internally -->
        <v-list-item v-else :to="item.url" :color="color" :key="item.name">
          <v-list-item-title>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center pb-0">
                <v-icon> {{ item.icon }} </v-icon>
              </v-col>
              <v-col cols="12" class="text-center grey--text pt-0">
                <span class="subtitle-2">{{ item.name }}</span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- <v-list-item to="/planner/lauXOIyD1kCUfWbRLRel-pYACeLj" color="grey">
        <v-list-item-action>
          <v-icon>mdi-map</v-icon>
        </v-list-item-action>
        <v-list-item-title
          ><span class="subtitle-2">Road map</span></v-list-item-title
        >
      </v-list-item> -->

      <!-- <v-list-item to="/test" color="grey">
        <v-list-item-action>
          <v-icon>mdi-map</v-icon>
        </v-list-item-action>
        <v-list-item-title>Test</v-list-item-title>
      </v-list-item>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    sidebar: { type: Boolean, default: true }
  },
  data() {
    return {
      color: "indigo lighten-2",
      items: [
        {
          name: "Run",
          icon: "mdi-play-circle-outline",
          url: "/run"
        },
        { name: "Results", icon: "mdi-trending-up", url: "/status" },
        {
          name: "Workers",
          icon: "mdi-account-supervisor-circle",
          url: "/admin"
        },
        { name: "FAQ", icon: "mdi-help-circle-outline", url: "/faq" },
        {
          name: "Docs",
          icon: "mdi-book-open-page-variant",
          url: this.$store.state.docs,
          external: true
        }
      ]
    };
  },
  methods: {
    toUrl(url) {
      return window.open(url);
    }
  }
};
</script>
