<template>
  <v-toolbar color="white transparent" flat dense>
    <!-- <v-btn icon to="/status">
      <v-icon color="indigo">mdi-chevron-left</v-icon>
    </v-btn> -->
    <v-avatar class="mr-4" size="40px" color="grey lighten-4">
      <img :src="disciplineIcon" alt="avatar" />
    </v-avatar>
    <v-toolbar-title>
      <span class="title font-weight-light">{{ name }}</span>
    </v-toolbar-title>
    <v-divider class="mx-4" vertical></v-divider>
    <span class="grey--text title font-weight-light">
      {{ getTitle }}
    </span>
    <v-spacer></v-spacer>
    <v-tooltip top>
      <template slot="activator" slot-scope="{ on }">
        <v-btn icon v-on="on" @click="refresh" color="indigo lighten-2">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Refresh</span>
    </v-tooltip>
    <v-tooltip top>
      <template slot="activator" slot-scope="{ on }">
        <v-btn
          icon
          :fab="logToggle"
          v-on="on"
          @click="toggleLog"
          :depressed="logToggle"
          :color="logButtonColor"
        >
          <v-icon>mdi-laptop</v-icon>
        </v-btn>
      </template>
      <span>Show Logs</span>
    </v-tooltip>

    <!-- <awf-publish></awf-publish> -->

    <!-- <awf-delete></awf-delete> -->
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
// import AwfDelete from "./AwfDelete.vue";

export default {
  // components: { AwfDelete }, removed due to confusion
  props: {
    runId: String,
    status: String,
    name: String,
    discipline: String,
    instance: String
  },
  data() {
    return {
      dialog: false,
      logToggle: false,
      avatar: null
    };
  },
  computed: {
    disciplineIcon() {
      return this.$store.getters.getDisciplineIcon(this.discipline);
    },
    getTitle() {
      if (!this.instance) return;
      return this.instance.replace("_", " ");
    },
    logButtonColor() {
      if (this.logToggle) return "black";
      else return "indigo lighten-2";
    }
  },
  methods: {
    toggleLog(event) {
      this.logToggle = !this.logToggle;
      this.$emit("toggleLog", this.logToggle);
    },
    refresh(event) {
      this.$emit("refresh", event);
    },
    back(event) {
      this.$emit("back", event);
    }
  }
};
</script>
