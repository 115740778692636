<template>
  <v-card>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-form v-model="valid" ref="form">
          <text-inputs
            :parameters="parameters"
            @jobNumberSelected="jobNumber = $event"
          ></text-inputs>

          <dialog-box
            v-if="dialog"
            :dialog="dialog"
            :param="node"
            :workflowUrl="workflow.url"
            @close="closeDialog"
          ></dialog-box>
          <v-snackbar v-model="alert" color="red" right bottom>
            Oops! There are some invalid fields in your workflow inputs.
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="alert = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-form>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-card class="pa-0 transparent" :height="diagramHeight" flat>
          <workflow-diagram
            :inputs="parameters"
            :submitted="false"
            :workflowConfig="workflow.config_json"
          ></workflow-diagram>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TextInputs from "@/components/workflows/TextInputs";
import DialogBox from "@/components/workflows/DialogBox";
import WorkflowDiagram from "@/components/workflows/WorkflowDiagram";

import { eventBus } from "../../main";
import { mapGetters } from "vuex";

export default {
  components: {
    WorkflowDiagram,
    TextInputs,
    DialogBox
  },
  props: {
    workflow: {
      type: Object
    }
  },
  data() {
    return {
      valid: false,
      alert: false,
      dialog: false,
      node: null,
      uploading: false,
      alertMessage: "",
      jobNumber: null,
      parameters: this.workflow.arguments,
      invalidInputs: [],
      noJobNumberMessage: "Please enter a job number",
      timeout: null
    };
  },
  created() {
    //Listen to events from the Workflow Diagram when a node is clicked
    eventBus.$on("nodeClicked", this.toggleDialog);

    //Listen for changes in Workflow Arguments
    eventBus.$on("argChanged", this.updateArg);
  },
  computed: {
    fileRequired() {
      return this.parameters.filter(p => p.type === "file").length > 0;
    },
    diagramHeight() {
      return 0.7 * window.outerHeight;
    }
  },
  methods: {
    ...mapGetters(["username"]),
    toggleDialog(node) {
      if (!node || !node.classes || !node.data) {
        console.error("Clicked node is empty");
      }

      this.node = node.data.value;

      if (
        node.classes.includes("input") ||
        node.classes.includes("inputFilled")
      ) {
        this.dialog = true;
        return;
      }

      if (node.classes.includes("task")) {
        console.log("TASK CLICKED");
        return;
      }

      if (node.classes.includes("submit")) {
        this.submit();
      }
    },
    closeDialog() {
      this.node = null;
      this.dialog = false;
    },
    updateArg(argument) {
      if (!this.parameters) {
        console.error(
          "Cannot change argument since workflow doesn't have any arguments"
        );
        return;
      }
      if (!argument || Object.keys(argument).length == 0 || !argument.name) {
        console.error("Cannot change argument since argument is empty");
        return;
      }

      // Replace workflow argument with new value
      let args = this.parameters.map(arg => {
        if (arg.name == argument.name) {
          return argument;
        } else return arg;
      });

      // Update parameters field to trigger updates on all child components
      this.parameters = [...args];
    },

    submit() {
      this.valid = true;

      // Validate form inputs
      if (!this.$refs.form.validate()) {
        this.valid = false;
      }

      // Validate that all workflow argument values have been set
      this.invalidInputs = this.parameters.filter(p => !p.value);
      if (this.invalidInputs.length > 0) {
        this.alert = true;
        this.valid = false;
        eventBus.$emit(
          "invalidForm",
          this.invalidInputs.map(p => p.label)
        );
        return;
      }

      //If form is invalid, return false
      if (!this.valid) {
        return;
      }

      var payload = {};

      //Build up workflow arguments
      var args = [];

      for (var i in this.parameters) {
        args.push(this.parameters[i].name + "=" + this.parameters[i].value);
      }

      payload["object"] = this.parameters.find(
        v => v.name === "object_id"
      ).value;
      payload["arguments"] = args.join(",");
      payload["job_number"] = this.jobNumber;

      this.$emit("submit", payload);
    }
  }
};
</script>
