<template>
  <v-navigation-drawer width="100%">
    <v-toolbar flat dense class="grey lighten-4">
      <v-toolbar-title class>
        <span class="title font-weight-light">Workflow runs</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="syncClicked">
        <v-icon>mdi-sync</v-icon>
      </v-btn>
    </v-toolbar>

    <v-virtual-scroll
      :items="items"
      :item-height="75"
      :height="0.85 * screenHeight"
    >
      <template v-slot:default="{ item }">
        <v-list-item
          :key="`run-${item.id}`"
          avatar
          ripple
          selectable
          @click="runSelected(item)"
        >
          <v-list-item-avatar>
            <img :src="getDisciplineIcon(item.workflow__discipline)" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="item.object"></v-list-item-title>
            <v-list-item-subtitle
              >{{ item.workflow__label }} &mdash;
              {{ statusMessage(item) }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-progress-linear
              v-if="isRunning(item.status)"
              color="indigo"
              height="15"
              :indeterminate="isRunning(item.status)"
            ></v-progress-linear>
            <v-icon color="green" v-else-if="isSuccessful(item.status)">
              mdi-check
            </v-icon>
            <v-icon color="blue" v-else-if="isSubmitted(item.status)">mdi-arrow-right</v-icon>
            <v-icon color="red" v-else>mdi-alert-circle-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    items: { type: Array }
  },
  data() {
    return {
      maxTerminated: 5
    };
  },
  computed: {
    ...mapGetters(["getDisciplineIcon", "screenHeight"])
  },
  methods: {
    formatDate(timeString) {
      var date = new Date(timeString);

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      return (
        monthNames[date.getMonth()] +
        " " +
        date.getDate() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    isSubmitted(statusCode) {
      if (statusCode === -1) return true;
      return false;
    },
    isRunning(statusCode) {
      if (statusCode === 0 || statusCode === 1) return true;
      return false;
    },
    isSuccessful(statusCode) {
      if (!statusCode) return false;

      //A workflow status code of 2 means it was successful
      if (Number(statusCode) === 2) return true;
      else return false;
    },
    timeElapsed(timeString) {
      var elapsed = Date.now() - new Date(timeString);
      var seconds = Math.floor(elapsed / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);
      var months = Math.floor(days / 30);
      if (seconds < 60) return seconds + " seconds";
      if (minutes < 60) return minutes + " minutes";
      if (hours < 24) return hours + " hours";
      if (days < 30) return days + " days";
      return months + " months";
    },

    statusMessage(item) {
      if (isNaN(item.status)) return "Could not find status";

      //Queue status message
      if (item.status === -1)
        return "Submitted " + this.timeElapsed(item.date_created) + " ago";
      else if (item.status === 0 || item.status === 1)
        return "Started " + this.timeElapsed(item.date_created) + " ago";
      else if (item.status > 1) {
        return "Completed on " + this.formatDate(item.date_updated);
      }
    },
    syncClicked() {
      this.$emit("fetchRuns");
    },
    runSelected(item) {
      this.$router.push("/status/run/" + item.id);
    }
  }
};
</script>

<style></style>
