<template>
  <v-img contain :height="height" :src="url"></v-img>
</template>

<script>
export default {
  props: { height: { default: 150, type: Number } },
  data() {
    return {
      url:
        "https://i.pinimg.com/originals/45/12/4d/45124d126d0f0b6d8f5c4d635d466246.gif"
    };
  }
};
</script>
