<template>
  <v-toolbar color="transparent" dense flat>
    <v-toolbar-title>
      <span class="headline">{{ title }}</span>
    </v-toolbar-title>
    <v-divider class="mx-4" vertical></v-divider>
    <span class="grey--text title font-weight-light"> {{ subtitle }} </span>
    <v-spacer></v-spacer>

    <template v-for="(btn, i) in btns">
      <v-tooltip top :key="i">
        <template slot="activator" slot-scope="{ on }">
          <span class="subtitle-2 font-weight-light grey--text" v-on="on">
            <v-btn
              color="indigo lighten-2"
              :to="btn.nav"
              fab
              dark
              :text="btn.flat"
            >
              <v-icon>{{ btn.icon }}</v-icon>
            </v-btn>
          </span>
        </template>
        <span>{{ btn.tooltip }}</span>
      </v-tooltip>
    </template>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
    btns: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>
