<template>
  <v-container class="px-0">
    <v-card flat max-width="800">
      <v-toolbar outlined flat dense>
        <v-icon>{{ icon }}</v-icon>

        <v-toolbar-title class="ml-4 subtitle-1">
          <span>{{ title }}</span></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="py-0">
        <v-list>
          <template v-if="items && items.length > 0">
            <template v-for="item in sortedItems">
              <!-- <v-divider :key="i" v-if="i != 0"></v-divider> -->
              <v-list-item
                :key="item.key"
                v-on:click="download(item.url)"
                avatar
              >
                <v-list-item-avatar>
                  <v-chip color="grey" text-color="white" small>{{
                    getExt(item.name)
                  }}</v-chip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="getName(item.name)"
                  ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span class="grey--text body-2"
                    >Created on {{ getDate(item.date_created) }}</span
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </template>
          <v-list-item v-else>
            <v-list-item-content>
              <v-col>{{ emptyMessage }}</v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["icon", "title", "items"],
  data() {
    return {
      emptyMessage: "There are no files generated by the workflow"
    };
  },
  computed: {
    sortedItems() {
      let copy = [...this.items];
      return copy.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  },
  methods: {
    async download(resourceUrl) {
      const url = this.$store.getters.downloadLink(resourceUrl);
      const s3url = await this.$store.dispatch("downloadFile", url);
      window.open(s3url);
    },
    getName(fileName) {
      return this.$store.getters.formatFileName(fileName);
    },
    getExt(fileName) {
      return this.$store.getters.getFileExt(fileName);
    },
    getDate(dateString) {
      return this.$store.getters.formatDate(dateString);
    }
  }
};
</script>

<style></style>
