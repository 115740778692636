<template>
  <v-container
    align="center"
    justify="center"
    :style="fullHeight"
    fill-height
    fluid
  >
    <v-row align="center" justify="center">
      <v-col sm="12" lg="6">
        <p class="display-3 text-center">
          Welcome to AWF
        </p>
        <p class="headline grey--text font-weight-light text-center">
          Create and run your own automated workflows
        </p>
        <p class="text-center">
          <v-btn color="indigo" @click="login" dark large>Get Started</v-btn>
        </p>
      </v-col>

      <v-col sm="12" lg="6">
        <v-card flat class="transparent" fill-height>
          <v-card-title class="pa-0">
            <v-img :src="diagram"></v-img>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      diagram: require("../assets/awf_workflow.png"),

      alert: false
    };
  },
  computed: {
    ...mapGetters(["fullHeight", "centerScreen"])
  },
  methods: {
    login() {
      var response = this.$store
        .dispatch("ssoLogin")
        .then(response => {
          this.successRedirect();
        })
        .catch(error => {
          this.alert = true;
        });
    },
    successRedirect() {
      return this.$router.push("./run");
    }
  }
};
</script>
