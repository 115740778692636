<template>
  <v-form v-model="valid" ref="form">
    <v-row justify="center">
      <v-col sm="8">
        <v-alert
          colored-border
          border="top"
          :value="alert"
          type="error"
          transition="scale-transition"
          outlined
          >{{ alertMessage }}</v-alert
        >
      </v-col>
      <v-col class="mt-4" sm="12">
        <p class="text-sm-center">
          <v-btn @click="submit" color="indigo" dark large>Log In</v-btn>
        </p>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      alert: false,
      show: false,
      alertMessage: "Username and/or password is incorrect",
      username: "",
      password: ""
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        this.snackbar = true;
        return false;
      }
      var response = this.$store
        .dispatch("ssoLogin")
        .then(response => {
          this.valid = true;
          this.successRedirect();
        })
        .catch(error => {
          this.valid = false;
          this.alert = true;
        });
    },
    successRedirect() {
      return this.$router.push("./run");
    }
  }
};
</script>
