import Home from "../views/Home.vue";
import Detail from "../views/Detail.vue";
import WorkflowRun from "../views/WorkflowRun.vue";
import Status from "../views/Status.vue";
import SignIn from "../views/SignIn.vue";
import FAQ from "../views/FAQ.vue";
import Contact from "../views/Contact.vue";
import Admin from "../views/Admin.vue";
import Docs from "../views/Docs.vue";
import { store } from "../store";

import VueRouter from "vue-router";

const ifNotAuthenticated = (to, from, next) => {
  store.dispatch("tryAutoLogin");
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("./run");
};

const ifAuthenticated = (to, from, next) => {
  store.dispatch("tryAutoLogin");
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("./signin");
};

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifNotAuthenticated,
    meta: { hideSideBar: true }
  },
  // {
  //   path: "/workflows",
  //   component: AwfUserWorkflows,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: "/status",
    name: "Status",
    component: Status,
    children: [
      {
        path: "run/:id",
        component: Detail
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/run",
    name: "WorkflowRun",
    component: WorkflowRun,
    beforeEnter: ifAuthenticated
  },

  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
    beforeEnter: ifAuthenticated
  },
  // {
  //   path: "/create",
  //   name: "AwfWorkflowCreate",
  //   component: AwfWorkflowCreate,
  //   beforeEnter: ifAuthenticated
  // },

  // {
  //   path: "/check",
  //   name: "AwfWorkflowCheck",
  //   component: AwfWorkflowCheck,
  //   beforeEnter: ifAuthenticated
  // },

  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: ifAuthenticated
  },

  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    beforeEnter: ifNotAuthenticated,
    meta: { hideSideBar: true }
  },
  // # TODO fix causes random issue with loading pages
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: Contact,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/docs",
    name: "Developer Docs",
    component: Docs,
    beforeEnter: ifAuthenticated
  }
];

export const router = new VueRouter({
  routes
});
