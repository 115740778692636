import Vue from "vue";
import Vuex from "vuex";
import { sso } from "../sso";
import { router } from "../router";
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    version: process.env.PACKAGE_VERSION,
    api: process.env.VUE_APP_API_URL,
    admin: process.env.VUE_APP_API_URL + "admin/",
    docs: process.env.VUE_APP_DOCS_URL,
    feedback: "https://feedback.arup.com/awf/38",
    userapi: "https://graph.microsoft.com/v1.0/",
    idToken: null,
    accessToken: null,
    expiresOn: null,
    username: null,
    profileImage: null,
    user: null,
    workflow: {
      runId: null,
      status: null,
      detail: null,
      resources: null
    },
    workflows: [],
    requestObj: {
      scopes: ["user.read", "openid"]
    }
  },
  mutations: {
    storeUser(state, user) {
      if (!user) return;
      state.user = user;
      state.username = user.displayName;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearAuthData(state) {
      state.user = null;
      state.accessToken = null;
      state.idToken = null;
      state.expiresOn = null;
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      localStorage.removeItem("expiresOn");
    },
    storeProfileImage(state, image) {
      state.profileImage = image;
    },
    storeToken(state, token) {
      state.accessToken = token.accessToken;
      state.idToken = token.idToken?.rawIdToken || token.idToken;
      state.expiresOn = token.expiresOn;
      localStorage.setItem("accessToken", token.accessToken);
      localStorage.setItem(
        "idToken",
        token.idToken?.rawIdToken || token.idToken
      );
      localStorage.setItem("expiresOn", token.expiresOn);
    },
    storeRunStatus(state, status) {
      state.workflow.status = status;
    },
    storeRunDetail(state, detail) {
      state.workflow = { ...state.workflow, ...detail };
    },
    storeRunResources(state, resources) {
      state.workflow.resources = resources;
    },
    storeRun(state, run) {
      if (run == null) state.workflow = {};
      else state.workflow = run;
    },
    storeWorkflows(state, workflows) {
      if (workflows == null) state.workflows = [];
      else state.workflows = workflows;
    },
    removeRun(state) {
      state.workflow = null;
    }
  },
  getters: {
    user(state) {
      if (!state.user) return;
      let user = { ...state.user };
      delete user["@odata.context"];
      return user;
    },
    username(state) {
      if (!state.user) return;
      return state.user.userPrincipalName;
    },
    userLabel(state) {
      if (!state.user) return;
      return state.user.displayName;
    },
    profileImage(state) {
      return state.profileImage;
    },
    api(state) {
      return state.api;
    },
    workflow(state) {
      return state.workflow;
    },
    getDisciplineIcon(state) {
      return discipline => {
        let icons = {
          seismic: require("../assets/seismic.jpg"),
          geotechnical: require("../assets/geotechnical.jpg"),
          infrastructure: require("../assets/civil.jpg"),
          structural: require("../assets/structural.jpg"),
          "building physics": require("../assets/building_physics.jpg"),
          "transport planning": require("../assets/transport_planning.jpg")
        };

        //Default icon if nothing is found
        if (!(discipline in icons)) return require("../assets/lab.jpg");
        return icons[discipline];
      };
    },
    getProfileIcon(state) {
      let dir = "../assets/images/logos/";
      let icons = {
        primer: require(dir + "primer.png"),
        reporter: require(dir + "reporter.jpg"),
        python: require(dir + "python.png"),
        speckle: require(dir + "speckle.png"),
        gsa: require(dir + "gsa.png")
      };
      return profile => {
        //Default icon if nothing is found
        if (!(profile in icons)) return;
        return icons[profile];
      };
    },
    isAuthenticated(state) {
      const token = state.accessToken;
      const idToken = state.idToken;
      const expiresOn = state.expiresOn;

      if (
        !token ||
        !idToken ||
        idToken == "null" ||
        token == "null" ||
        !expiresOn ||
        expiresOn == "null"
      ) {
        console.error("No access token found");
        return false;
      }

      //Check if token has expired

      if (new Date() - new Date(expiresOn) > 0) {
        console.error("Access token has expired");
        return false;
      }

      console.log("User is authenticated");
      return true;
    },
    downloadLink(state) {
      return resourceUrl => {
        return resourceUrl + "download/";
      };
    },
    formatFileName(state) {
      return name => {
        return name.split(".")[0].replace(/_/g, " ");
      };
    },
    timeElapsed(state) {
      return timeString => {
        var elapsed = Date.now() - new Date(timeString);
        var seconds = Math.floor(elapsed / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        if (seconds < 60) return seconds + " seconds";
        if (minutes < 60) return minutes + " minutes";
        if (hours < 24) return hours + " hours";
        if (days < 30) return days + " days";
        return months + " months";
      };
    },
    formatDate(state) {
      return dateString => {
        var date = new Date(dateString);

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];

        return (
          monthNames[date.getMonth()] +
          " " +
          date.getDate() +
          " " +
          ("0" + date.getHours()).slice(-2) +
          ":" +
          ("0" + date.getMinutes()).slice(-2)
        );
      };
    },

    centerScreen(state) {
      return "height: " + window.outerHeight / 2 + "px";
    },
    screenHeight(state) {
      return Math.round(window.innerHeight);
    },
    fullHeight(state) {
      return "height: " + Math.round(window.innerHeight * 0.8) + "px";
    },
    getFileExt(state) {
      return name => {
        return name.split(".")[1];
      };
    },
    nameRules(state) {
      return;
      [
        v => !!v || "Name is required",
        v => (v && v.length <= 10) || "Name must be less than 10 characters"
      ];
    },
    passwordRules(state) {
      return [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 8) || "Password must be greater than 8 characters"
      ];
    },
    emailRules(state) {
      return [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ];
    },
    groupBy(state) {
      return (arr, key) => {
        return arr.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
    }
  },
  actions: {
    ssoLogin({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        sso
          .loginPopup(this.state.requestObj)
          .then(response => {
            //Login Success callback code here
            return resolve(dispatch("acquireToken"));
          })
          .catch(function(error) {
            console.log(error);
            return reject(error);
          });
      });
    },

    acquireToken({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        sso
          .acquireTokenSilent(this.state.requestObj)
          .then(function(response) {
            // Callback code here
            console.log("Acquire Token Silent");
            commit("storeToken", response);
            dispatch("setLogoutTimer", response.expiresOn - new Date());

            return resolve(dispatch("getUserInfo"));
          })
          .catch(function(error) {
            return reject(console.error(error));
          });
      });
    },
    setLogoutTimer({ dispatch }, expirationTime) {
      console.log("Logout Timer set to: ", expirationTime);
      setTimeout(() => {
        dispatch("logout");
      }, expirationTime);
    },
    getUserInfo({ state, commit, dispatch }) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "me", { headers: header })
          .then(
            response => {
              var userData = response.body;
              commit("storeUser", userData);
              // return resolve(userData);
              return resolve(userData);
            },
            error => {
              console.error("Failed to get User Info");
              console.error(error);
              return reject(error);
            }
          )
          .catch(error => {
            console.error("Caught Error");
            console.error(error);
            return reject(error);
          });
      });
    },
    getMyPhoto({ state, dispatch }) {
      console.log("GETTING USER PHOTO");
      var header = {
        Authorization: "Bearer " + state.accessToken,
        "Content-Type": "image/jpeg"
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "me/photo/$value", {
            headers: header,
            responseType: "blob"
          })
          .then(response => {
            var blobUrl = URL.createObjectURL(response.body);
            state.profileImage = blobUrl;

            return resolve(blobUrl);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getUserPhoto({ state, commit }, username) {
      var header = {
        Authorization: "Bearer " + state.accessToken,
        "Content-Type": "image/jpeg"
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "users/" + username + "/photo/$value", {
            headers: header,
            responseType: "blob"
          })
          .then(response => {
            var blobUrl = URL.createObjectURL(response.body);

            return resolve(blobUrl);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    tryAutoLogin({ commit }) {
      console.log("Trying Auto Login...");

      //First check state if user has been authenticated already - if not login
      // if (getters.isAuthenticated) {
      //   console.log("User is authenticated in STATE");
      //   return true;
      // }

      const user = localStorage.getItem("user");
      const accessToken = localStorage.getItem("accessToken");
      const idToken = localStorage.getItem("idToken");
      const expiresOn = localStorage.getItem("expiresOn");

      //If there is none in the localstorage, then autologin fails
      if (!user || !accessToken || !expiresOn) {
        console.log("NO AUTH FOUND IN LOCAL STORAGE");
        return false;
      }
      //If state has been cleaned due to refresh - Store user from local storage
      commit("storeUser", JSON.parse(user));
      commit("storeToken", {
        idToken: idToken,
        accessToken: accessToken,
        expiresOn: expiresOn
      });

      console.log("User is already authenticated in STORE");

      return true;
    },

    logout({ commit }) {
      commit("clearAuthData");
      console.debug("LOGGING OUT...");
      // sso.logout();
      router.replace("/signin");
    },

    get({ state }, url) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(url, {
            headers: header
          })
          .then(response => {
            return resolve(response.body);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getUser({ state, dispatch }, username) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "users/" + username, {
            headers: header
          })
          .then(response => {
            let user = response.body;

            dispatch("getUserPhoto", username).then(photo => {
              user.photo = photo;
            });
            return resolve(user);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getGroups({ state, commit }) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "groups/", {
            headers: header
          })
          .then(response => {
            let groups = response.body.value;
            return resolve(groups);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getPlans({ state, commit }, groupId) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "groups/" + groupId + "/planner/plans", {
            headers: header
          })
          .then(response => {
            let plans = response.body.value;
            return resolve(plans);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getPlanBuckets({ state, commit }, planId) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "planner/plans/" + planId + "/buckets", {
            headers: header
          })
          .then(response => {
            let plans = response.body.value;
            return resolve(plans);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getBucketTasks({ state, commit }, bucketId) {
      var header = {
        Authorization: "Bearer " + state.accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "planner/buckets/" + bucketId + "/tasks", {
            headers: header
          })
          .then(response => {
            let tasks = response.body.value;
            let payload = { bucketId: bucketId, tasks: tasks };

            return resolve(payload);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getTasks({ state, commit }, planId) {
      let accessToken;
      if (!state.accessToken) {
        accessToken = localStorage.getItem("accessToken");
      } else {
        accessToken = state.accessToken;
      }

      var header = {
        Authorization: "Bearer " + accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "planner/plans/" + planId + "/tasks", {
            headers: header
          })
          .then(response => {
            let tasks = response.body.value;
            return resolve(tasks);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getTask({ state }, taskId) {
      let accessToken;
      if (!state.accessToken) {
        accessToken = localStorage.getItem("accessToken");
      } else {
        accessToken = state.accessToken;
      }

      var header = {
        Authorization: "Bearer " + accessToken
      };

      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.userapi + "planner/tasks/" + taskId + "/details", {
            headers: header
          })
          .then(response => {
            let task = response.body;
            return resolve(task);
          })
          .catch(error => {
            console.error(error.body.error.message);
            return reject(error);
          });
      });
    },
    getJobNumber({ state }, jobNumber) {
      if (jobNumber) {
        var header = {
          Authorization: "Bearer azuread-oauth2 " + state.idToken
        };
        return new Promise((resolve, reject) => {
          Vue.http
            .get(this.state.api + "jn-autocomplete/" + jobNumber + "/", {
              headers: header
            })
            .then(response => {
              let payload = response.data;
              if (!payload) return reject(null);
              return resolve(payload.value);
            })
            .catch(error => {
              return reject(error);
            });
        });
      }
    },
    getJobName({ state }, jobName) {
      if (jobName) {
        var header = {
          Authorization: "Bearer azuread-oauth2 " + state.idToken
        };
        return new Promise((resolve, reject) => {
          Vue.http
            .get(
              this.state.api +
                "ads/Jobs/?$filter=startswith(JobNameLong, '" +
                jobName +
                "')&$top=10",
              { headers: header }
            )
            .then(response => {
              let payload = response.data;
              if (!payload) return reject(null);
              return resolve(payload.value);
            })
            .catch(error => {
              return reject(error);
            });
        });
      }
    },
    getWorkers({ state, commit }) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.api + "worker/", { headers: header })
          .then(
            response => {
              const result = response.body;
              console.log("Getting workers...");
              return resolve(result.results);
            },
            error => {
              return reject(error);
            }
          )
          .catch(data => {
            return reject(data);
          });
      });
    },
    getWorkflows({ state, commit, dispatch }, runId) {
      var params = { public: true, limit: 100 }; // if we leave out limit for some reason the first workflow is undefined

      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };

      return new Promise((resolve, reject) => {
        // commit("storeRunId", runId);
        Vue.http
          .get(this.state.api + `workflow/`, {
            params: params,
            headers: header
          })
          .then(
            response => {
              const result = response.body;
              console.log("Getting workflows...");
              //commit("storeRunDetail", result);
              return resolve(result.results);
            },
            error => {
              console.log(error.status);
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.log(data);
            reject(data);
          });
      });
    },
    getWorkflow({ state, commit, dispatch }, url) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };

      return new Promise((resolve, reject) => {
        // commit("storeRunId", runId);
        Vue.http
          .get(url, {
            headers: header
          })
          .then(
            response => {
              const result = response.body;
              console.log("Getting workflow...");
              //commit("storeRunDetail", result);
              return resolve(result);
            },
            error => {
              console.log(error.status);
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.log(data);
            reject(data);
          });
      });
    },
    getWorkflowRun({ state, commit, dispatch }, runId) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      commit("storeRun", null);
      return dispatch("getWorkflowStatus", runId).then(() => {
        return dispatch("getWorkflowResources", runId).then(() => {
          return new Promise((resolve, reject) => {
            Vue.http
              .get(this.state.api + `run/${runId}/`, {
                headers: header
              })
              .then(
                response => {
                  const result = response.body;
                  commit("storeRun", result);
                  resolve(this.getters.workflow);
                },
                error => {
                  console.log(error.status);
                  console.error(error);
                  return reject(error);
                }
              )
              .catch(data => {
                console.log("Caught Error");
                console.log(data);
                reject(data);
              });
          });
        });
      });
    },
    getWorkflowRuns({ state, getters, commit }) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        const data = { owner__iexact: getters.user.mail };
        Vue.http
          .get(this.state.api + "run/", {
            params: data,
            headers: header
          })
          .then(
            response => {
              return resolve(response.body.results);
            },
            error => {
              console.error(error.status);
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.error(data);
            return reject(data);
          });
      });
    },

    getWorkflowStatus({ state, commit }, runId) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .get(this.state.api + `run/${runId}/`, {
            headers: header
          })
          .then(
            response => {
              const result = response.body;
              commit("storeRunStatus", result);

              return resolve(result);
            },
            error => {
              console.log(error.status);
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.error(data);
            return reject(data);
          });
      });
    },
    getWorkflowResources({ state, commit }, runId) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        const data = { run_id: runId };
        Vue.http
          .get(this.state.api + "resource/", {
            params: data,
            headers: header
          })
          .then(
            response => {
              const resources = response.body.results;
              commit("storeRunResources", resources);
              return resolve(resources);
            },
            error => {
              if (error.status === 404) {
                const resources = [];
                commit("storeRunResources", resources);
                return resolve(resources);
              } else {
                return reject(error);
              }
            }
          )
          .catch(data => {
            console.error(data);
            return reject(data);
          });
      });
    },
    postResource({ state, commit, dispatch }, payload) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        console.log("Posting upload details file to API...");

        Vue.http
          .post(this.getters.api + "resource/", payload, {
            headers: header
          })
          .then(
            response => {
              console.log("Upload signed url retrieved");
              return resolve(response.body);
            },
            error => {
              console.error(error.status);
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.error(data);
            return reject(data);
          });
      });
    },
    uploadFileToSignedUrl({ commit, dispatch }, { file, signed_url, api_url }) {
      const rollbackDatabaseRecord = () => {
        Vue.http
          .delete(api_url)
          .then(
            _ => console.log("Record successfully deleted from database"),
            error => {
              console.log(error.status);
              console.log(error);
            }
          )
          .catch(data => {
            console.log("CaughtError");
            console.error(data);
          });
      };
      return new Promise((resolve, reject) => {
        //Use default file type if none was provided
        if (!file.type) var fileType = "application/octet-stream";
        else if (file.type === "application/json") var fileType = "";
        else var fileType = file.type;
        Vue.http
          .put(signed_url, file, {
            headers: { "Content-Type": fileType }
          })
          .then(
            response => {
              console.log("File uploaded to file storage");
              return resolve(response.body);
            },
            error => {
              console.error(error.status);
              console.error(error);
              rollbackDatabaseRecord();
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.error(data);
            rollbackDatabaseRecord();
            return reject(data);
          });
      });
    },
    postWorkflowRun({ state, commit }, payload) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .post(this.state.api + "run/", payload, {
            headers: header
          })
          .then(
            response => {
              commit("storeRun", response.body);
              return resolve(response.body);
            },
            error => {
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.error(data);
            return reject(data);
          });
      });
    },
    patchWorkflowRun({ state, commit }, { runUrl, payload }) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .patch(runUrl, payload, { headers: header })
          .then(
            response => {
              return resolve(response.body);
            },
            error => {
              console.error(error);
              return reject(error);
            }
          )
          .catch(data => {
            console.error(data);
            return reject(data);
          });
      });
    },
    deleteWorkflowRun({ state, commit }, runId) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .delete(this.state.api + `run/${runId}/`, {
            headers: header
          })
          .then(
            response => {
              return resolve(response);
            },
            error => {
              console.error(error.status);
              return reject(error);
            }
          )
          .catch(data => {
            console.log("Caught Error");
            console.log(data);
            return reject(data);
          });
      });
    },
    downloadFile({ state, commit }, url) {
      var header = {
        Authorization: "Bearer azuread-oauth2 " + state.idToken
      };
      return new Promise((resolve, reject) => {
        Vue.http
          .get(url, { headers: header })
          .then(response => {
            return resolve(response.body.signed_url);
          })
          .catch(data => {
            console.log("Caught Error");
            console.log(data);
            return reject(data);
          });
      });
    }
  }
});
